<template>
<div style="padding-top: 35px">
    <div class="d-block row">
        <div class="col-lg-12">
            <div class="row" style="margin-top: -48px !important; margin-bottom: 10px !important">
                <!-- <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="InsideIndex" @input="tabChange()">
                    <b-tab title="All"> </b-tab>
                    <b-tab title="Pending"> </b-tab>
                    <b-tab title="Completed"> </b-tab>
                    <b-tab title="Drafts"> </b-tab>
                </b-tabs> -->

                 <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="OutsideIndex" @input="tabChange()">
                  <b-tab title="Requests"> </b-tab>
                    <b-tab title="Drafts"> </b-tab>
                </b-tabs>
            </div>
            <div class="row" :style="{
            'margin-bottom': index != 0 ? '10px' : 'auto',
          }">
                <button style="margin-left: 9px !important" class="btn btn-link text-uppercase Open-Filter" @click="openfilter()" v-show="!ShowFilter">
                    <b-img width="11" height="9" :src="openFilterIcon" /> Open Filter
                </button>
                <div class="offset-lg-3" v-show="ShowFilter">
                    <button style="margin-left: -12px !important" class="btn btn-link text-uppercase Close-Filter" @click="closefilter()">
                        <b-img width="11" height="9" :src="closeFilterIcon" /> Close
                        Filter
                    </button>
                </div>
            </div>

            <div class="row mb-3">
                <!-- <div v-bind:class="[
              {
                'col-md-6 ': !ShowFilter,
                'col-md-5 offset-md-3': ShowFilter,
              },
            ]">
                    <div class="row">
                        <div class="col-lg-6">
                            <input type="text" class="form-control" v-model="filter" placeholder="Search" />

                        </div>

                    </div>
                </div> -->
                 <div v-bind:class="[
              {
                'col-md-6 ': !ShowFilter,
                'col-md-4 offset-md-3': ShowFilter,
              },
            ]">
                    <div class="row" v-if="OutsideIndex==0">
                        <div class="col-lg-4" :class="{ 'col-lg-5': ShowFilter }">
                            <OmniSelect v-model="InsideIndex" :options="FilterTabOptions" name="" :showLabel="false" placeholder="" :showSearch="false" />

                        </div>
                        <div class="col-lg-6">
                            <input type="text" class="form-control" v-model="filter" placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div v-bind:class="[
              {
                'col-md-6 text-right ': !ShowFilter,
                'col-md-5 text-right': ShowFilter,
              },
            ]">
                    <button class="btn custom-button btn-primary" @click="CreateRequest()" id="create-profile-request" text="Create New" v-if="HasAccessToAnyRequests">
                        CREATE REQUEST
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3" v-if="ShowFilter">
                    <div>
                        <div class="form-group col-md-12">
                            <OmniSelect class="omniipclass" :options="StatusOptions" v-model="FilterList.Status" name="STATUS" placeholder="Filter by Status" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" />
                        </div>
                        <div class="form-group col-md-12">
                            <label class="basic-class">Completion Date</label>
                            <date-range-picker style="min-width: 98%" ref="picker" opens="right" :ranges="false" v-model="FilterList.CompletionDate" placeholder="Filter by CompletionDate" :locale-data="locale">
                                <template v-slot:input="picker">
                                    <span v-if="IsValidDate(picker)">
                                        {{ picker.startDate | formatDate }} -
                                        {{ picker.endDate | formatDate }}</span>
                                    <span v-else class="placeholder">
                                        Filter by Completion Date
                                    </span>
                                </template>
                            </date-range-picker>
                        </div>
                        <div class="row text-right">
                            <div class="col-md-11 p-0">
                                <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="RestValues()">
                                    RESET
                                </button>
                                <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="FilterQuery()">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[
              { 'col-md-12 ': !ShowFilter, 'col-md-9': ShowFilter },
            ]">
                    <b-table id="all-items" :per-page="perPage" :items="data.rows" :fields="fields" no-local-sorting=true @sort-changed="sortChange" show-empty striped hover responsive class="all-items">

                        <template v-slot:cell(CreatedDate)="data">
                            <div class="text-center">
                                {{ data.value | formatDate }}
                            </div>
                        </template>
                        <template v-slot:cell(Actions)="data">
                            <div class="text-center action-cell">
                                <b-img width="12" height="12" :src="auditIcon" @click="showAuditTrail(data.item.ID, data.item.RequestType)" class="audit-trail" title="Audit Trail" :disable="InsideIndex==3" v-b-tooltip.hover.top />
                            </div>
                        </template>
                           <template v-slot:cell(Name)="data">
                            <a class="edit-link" @click="
                    navigateToEdit(
                      data.item.ID,
                      data.item.RequestType,
                      data.item.Status
                    )
                  ">{{formatName(data.item)}}</a>
                  <div style="font-size:12px;">{{formatRequested(data.item)}}</div>
                        </template>
                         <template v-slot:table-colgroup="scope" v-if="allRequest">
                            <col v-for="field in scope.fields" :key="field.key" :style="{
                    width: field.label==='Request'?'300px':field.key === 'audit' ? '50px':'auto',
                  }" />
                        </template>

<template v-slot:cell(RequestType)="data">
                            <div class="text-center">
                                {{ data.value  }}
                            </div>
                        </template>
                        <template v-slot:cell(CompletionDate)="data">
                            <div class="text-center">
                                {{ data.value | formatDate }}
                            </div>
                        </template>

                        <template v-slot:cell(Requestor)="data">
                            <div>
                                {{ formatRequestor(data.value) }}
                            </div>
                        </template>

                        <template v-slot:cell(Status)="data">
                            <div style="white-space: nowrap" class="text-left">
                                <span :style="getBackgroundColor(data.value)">
                                    {{ formatStatus(data.value) }}
                                </span>
                            </div>
                        </template>
                        <template slot="custom-foot" v-if="IsPaginationRequired">
                            <b-tr>
                                <b-td class="custom-pagination" colspan="13">
                                    <b-form-select class="records-per-page" v-model="perPage" :options="RecordsPerPageOption"></b-form-select>
                                    <Pagination style="float: right" :per-page="perPage" aria-controls="open-items" v-model="currentPage" :total-rows="TotalRows" first-number last-number />
                                </b-td>
                            </b-tr>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
    <component :is="currentAuditTrail" v-bind="{ id: requestId, closeAuditModal: closeAuditModal }" @closeAudit="closeAudit()"></component>
    <b-modal modal-class="cancel-request" dialog-class="cancel-request" v-model="ShowCancel" :no-close-on-backdrop="true" size="sm">
        <template v-slot:modal-header="{ close }">
            <h5 class="modal-title">Cancel wire transfer request</h5>
            <button class="close" @click="Cancel()"></button>
        </template>
        <div>
            <p class="cancel-info-text">
                Your wire transfer request will be canceled and this action is permanent. Please provide a brief reason for the cancelation.
            </p>
            <div>
                <b-textarea :placeholder="'Add details... Minimum 50 characters'" :rows="5" v-model="CancelNotes" class="">

                </b-textarea>
            </div>

        </div>
        <template v-slot:modal-footer="{ ok, cancel }">
            <div class="text-right footer-buttons" v-if="ShowCancel==true">
                <button class="btn custom-button btn-secondary" @click="Cancel()">
                    Never Mind
                </button>
                <button :disabled="ShowCancel==true&&$v.$invalid" class="btn custom-button btn-primary" @click="CancelRequest()">
                    Cancel Request
                </button>

            </div>
        </template>
    </b-modal>
</div>
</template>

<style>
.cancel-info-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #47505a;
}

.Custom-Edit {
    padding-left: 10px;
}

.Custom-Delete {
    padding-left: 10px;
}

.row-details-container {
    background-color: #fff;
    border: 1px solid #ececec;
    margin-left: 40px;
    margin-top: 10px;
    min-height: 60px;
    margin-right: 40px;
    margin-bottom: 10px;

}

.row-detail-title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
    padding: 10px 0px 0px 10px;
}

.row-details-container>.support-document:after {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    right: 0%;
    top: 10%;
    height: 80%;
    border-right: 1px solid #b7bcd6;
}

.row-details-notes {
    padding-left: 10px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.list-tab {
    font-size: 14px;
    color: #00a1d2 !important;
}

.tooltip-inner {
    background-color: #f7f7f9 !important;
    color: #ffffff !important;
}

.tooltip-inner::before {
    border-top-color: #f7f7f9 !important;
    border-bottom-color: #f7f7f9 !important;
    border-left-color: #f7f7f9 !important;
    border-right-color: #f7f7f9 !important;
}

a.edit-link {
    color: #00a1d2 !important;
}

table.b-table tbody tr td {
    line-height: 1.2 !important;
}

.popover-body>div>div:first-child>div {
    margin-top: 15px !important;
}

.popover-body>div>div:not(:first-child):not(:last-child)>div {
    margin-top: 20px !important;
}

.popover-body>div>div:last-child>div {
    margin-top: 20px !important;
    margin-bottom: 15px !important;
}

.popover-body>div div.left {
    height: 12px !important;
}

.b-table tbody>tr {
    height: 40px !important;
}

.table td {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

table tfoot tr td {
    padding-right: 0px !important;
}

.form-control[placeholder="Search"] {
    background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
}

/* .form-control[placeholder="Search"]:placeholder-shown {
    position:relative;
    right:0rem;
    background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
} */
/* .form-control[placeholder="Search"]::-webkit-search-cancel-button{
  -webkit-appearance: none;
    position:relative;
    right:-1.8rem;
} */
e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #00a1d2 !important;
    border-color: transparent !important;
    color: #fff !important;
}

.e-multi-select-list-wrapper .e-selectall-parent {
    margin-left: 40px !important;
}

.back-arrow:after {
    content: " \00AB";
    width: 4px;
    height: 7px;
}

.btn-link {
    color: #00a1d2 !important;
}

.All-Requests {
    width: 98px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"],
.table.b-table>thead>tr>[aria-sort="ascending"],
.table.b-table>tfoot>tr>[aria-sort="ascending"],
.table.b-table>thead>tr>[aria-sort="descending"],
.table.b-table>tfoot>tr>[aria-sort="descending"] {
    background-position-y: 25px !important;
    background-position-x: 95% !important;
}

.container2:hover p span {
    filter: invert(45%) sepia(81%) saturate(3821%) hue-rotate(167deg) brightness(102%) contrast(101%);
}

.container2:hover p {
    fill: #00a1d2 !important;
    color: #00a1d2 !important;
}

.iconDetails {
    margin-left: -28%;
    float: left;
    height: 14px;
    width: 40px;
}

.container2 {
    width: 270px;
    min-height: 25px;
    float: left;
}

.left {
    float: left;
    width: 45px;
}

.right {
    margin-left: -23px;
    float: left;
    width: 149px;
}

.Apply {
    margin-left: 17px;
    height: 19px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #88d5ec;
    border-radius: 25px !important;
}

.Apply:hover {
    text-decoration: none !important;
    color: white !important;
    background-color: #018db8 !important;
    border-radius: 25px !important;
}

.Filters {
    width: 49px;
    height: 24px;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.Open-Filter {
    /* width: 74px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.All-Requests {
    width: 123px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.Open-Filter {
    padding-top: 16px;
    /* width: 123px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Close-Filter {
    margin-left: 15px;
    padding-top: 20px;
    width: 150px;
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Container {
    width: 1268px;
    height: 353px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffffff;
}

.b-popover p {
    cursor: pointer !important;
}

.tooltip-inner {
    background-color: #2c3865 !important;
}

#body-container>.container {
    padding-top: 30px !important;
}

.btn-info:hover {
    color: #fff;
    background-color: #1388b7 !important;
}

table>tr>td {
    height: 40px !important;
}

.icon-add-client {
    background-image: url("../assets/Icons/add-client.svg");
}

.icon-add-product {
    background-image: url("../assets/Icons/add-product.svg");
}

.icon-add-intercompany {
    background-image: url("../assets/Icons/add-intercompany.svg");
}

.icon-add-vendor {
    background-image: url("../assets/Icons/add-vendor.svg");
}

.icon-custom {
    height: 19px;
    width: 19px;
    background-repeat: no-repeat;
    display: inline-block;
}

.card {
    border: 0px !important;
}

.filter-icon {
    background-image: url("../assets/Icons/f.png");
    background-repeat: no-repeat;
    vertical-align: super;
}

.display-hover {
    height: 20px;
    width: 20px;
    text-align: center;
}

tbody tr:not(:hover) .display-hover>img.action-icons-hover,
tbody tr:not(:hover) .display-hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:not(:hover)>img.action-icons-hover,
tbody tr:hover .display-hover:hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:hover>img.action-icons-hover,
tbody tr:hover .display-hover:not(:hover)>img.action-icons {
    display: inline-block;
}

tbody tr>td {
    height: 40px !important;
    cursor: default !important;
}

tbody tr>td .audit-trail {
    cursor: pointer !important;
}

td.NameHoverclass:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

td.NameHoverclass {
    overflow-wrap: anywhere !important;
    /* text-decoration: underline !important; */
}

#cardheader .card-header {
    background-color: rgba(127, 137, 152, 1) !important;
}

tbody {
    background-color: white !important;
}

.dataTables_length {
    display: none !important;
}

thead {
    background-color: rgba(123, 129, 136, 1) !important;
}

/* .nav-tabs {
  border-bottom: 1px solid white !important;
} */

.tabs {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.tab-content {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
    padding: 20px !important;
}

/* .input-group {
    display: none !important;
} */

.vbt-per-page-dropdown {
    display: none !important;
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: calc(100% - 31%) !important;
    }
}

.close {
    outline: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group:not(.e-float-icon-left) {
    border: 1px solid #ced4da !important;
    width: 290px !important;
}

.popoverhover {
    font-family: "Open Sans";
    font-size: 12px;
}

.popoverhoverproduct {
    font-family: "Open Sans";
    font-size: 12px;
}

.clientnamehover :hover {
    text-decoration: underline !important;
}

.omniipclass {
    font-family: "Open Sans" !important;
}

.Open-Filter img,
.Close-Filter img {
    vertical-align: initial !important;
}
</style>

<script>
const moment = require("moment");
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import * as IntercompanyWorkflowStates from "../utilities/IntercompanyWorkflowStates";
import {formatRequestor} from "../utilities/Helper";
import {
    requiredIf,
    minLength
} from "vuelidate/lib/validators";
import {UpdateRecordsPerPageOption} from "./../utilities/paginationUtils";
export default {
    components: {
        DateRangePicker,
    },
    name: "IntercompanyAgencies",
    data() {
        return {
            RecordsPerPageOption: [],
            CancelNotes: null,
            ShowCancel: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            collapseIcon: require("@/assets/Icons/arrow-down.svg"),
            expandIcon: require("@/assets/Icons/rightarrow.svg"),
            editIcon: require("@/assets/Icons/edit.svg"),
            FilterTabOptions: [{
                    label: 'All Requests',
                    value: "0"
                },
                {
                    label: 'Completed',
                    value: "1"
                },
                {
                    label: 'Pending Approval',
                    value: "2"
                },
                 {
                    label: 'To be Completed',
                    value: "3"
                },
            ],
            StatusOptions:[
                {
                  label:"Intercompany Approval",
                  value:"Intercompany Approval"
                },
                {
                  label:"Finance Approval",
                  value:"Finance Approval"
                },
                {
                  label:"D365 Approval",
                  value:"D365 Approval"
                },
                {
                  label:"Completed",
                  value:"Completed"
                },
                {
                  label:"Rejected",
                  value:"Rejected"
                }
            ],
            MasterClientCodes: [],
            locale: {
                applyLabel: "Set",
                cancelLabel: "Cancel",
            },
            button: "button title",
            InsideIndex: "0",
            OutsideIndex: 0,
            TotalRows: 0,
            requestType: null,
            requestId: 0,
            closeAuditModal: true,
            addClient: require("@/assets/Icons/add-client.svg"),
            addProduct: require("@/assets/Icons/add-product.svg"),
            addIntercompany: require("@/assets/Icons/add-intercompany.svg"),
            addVendor: require("@/assets/Icons/add-vendor.svg"),
            closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
            openFilterIcon: require("@/assets/Icons/open-filter-icon.svg"),
            auditIcon: require("@/assets/Icons/auditTrail_icon.svg"),
            filter: null,
            sortBy: "CreatedDate",
            sortDesc: true,
            theme: "row",
            ShowFilter: false,
            OmniflowStatus: {
                ICApproveLevel1: "Intercompany Approval",
                ICApproveLevel2: "Finance Approval",
                ICApproveLevel3: "D365 Approval",
                Rejected: "Rejected",
                Completed: "Completed",
            },
            FilterList: {
                PaymentType: null,
                CompanyName: null,
                Beneficiary: null,
                Status: [],
                CompletionDate:{}
            },
            RequestTypes: [{
                    value: "1",
                    label: "Client"
                },
                {
                    value: "2",
                    label: "Product"
                },
                {
                    value: "3",
                    label: "Inter Company"
                },
                {
                    value: "4",
                    label: "Vendor"
                },
            ],
            RequestTypeFields: {
                text: "RequestType",
                value: "Id"
            },

            Status: [],
            RequestStatusFields: {
                text: "RequestSts",
                value: "Id"
            },
            MediaTypeField: {
                text: "MediaTypeVal",
                value: "Id"
            },
            showSelectAll: true,
            getFixed: true,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            showfilter: false,
            showfilterOpenItems: false,
            perPage: 10,
            currentPage: 1,
            DateFormat: "MM/DD/YYYY",
            elegant: "black",
            fields: this.buildColumns(),
            data: {
                config: {
                    card_mode: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    pagination_info: true,
                    global_search: {
                        visibility: false,
                    },
                },
                loading: false,
                classes: {
                    tableWrapper: "outer-table-div-class wrapper-class-two grid-table",
                    table: {
                        "table-sm": true,
                    },
                },
                rows: [],
            },
        };
    },
    validations: {
        CancelNotes: {
            minLength: minLength(50),
            required: requiredIf(function () {
                return this.ShowCancel == true;
            })
        }

    },
    created() {
        this.$store.commit("client/reset");
        this.$on("closeAudit", function () {
            this.closeAudit();
        });
    },
    props: {
        allRequest: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        requestid: {
            type: Number,
            required: false,
        },
    },
    mounted() {
        if (this.CanAccessList) {
            this.GetRequests();
        } else {
            this.$router.push({
                name: "unauthorized",
                params: {
                    message: "UnAuthorized"
                },
            });
        }
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);
            window.console.log(this.$refs.picker);
        });
    },
    watch: {
        InsideIndex: function () {
            this.fields = this.buildColumns();
            this.GetRequests();
        },
        filter(e) {
            if ((e != null && e.length > 2) || e == null || e.length == 0) {
                this.GetRequests();
            }
        },
        currentPage: function (value) {
            if (value != null && parseInt(value) > 0) {
                this.GetRequests();
            }
        },
        perPage: function () {
            this.currentPage = 1;
            this.GetRequests();
        }
    },
    computed: {
        IsFormFilterValid() {
            return this.FilterList.Status.length > 0 || this.FilterList.CompletionDate.startDate != null;
        },
        IsPaginationRequired() {
            return this.TotalRows != null && parseInt(this.TotalRows) > parseInt(this.perPage);
        },

        HasAccessToAnyRequests() {
            return  this.CanCreateICRequest;
        },

        CanCreateICRequest() {
            return this.$store.getters.canCreateICRequest;
        },

        canApproveICRequestLevel1() {
            return this.$store.getters.canApproveICRequestLevel1;
        },
        canApproveICRequestLevel2() {
            return this.$store.getters.canApproveICRequestLevel2;
        },
        canApproveICRequestLevel3() {
            return this.$store.getters.canApproveICRequestLevel3;
        },

        currentAuditTrail() {
            return this.closeAuditModal != true ?
                this.requestType === "Client" ?
                "client-audit" :
                this.requestType === "Product" ?
                "product-audit" :
                this.requestType === "Intercompany" ?
                "ic-audit" :
                this.requestType === "Profile Unlock" ?
                "profile-audit" :
                "" : "";
        },

        CanAccessList() {
            return this.$store.getters.canAccessList;
        },
    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
        },
        formatDateAndTime(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY h:mma") : "-";
        },

    },
    methods: {
        CreateRequest(){
            this.$router.push('/Request/InterCompanyAgency');
        },
        DeleteRequest() {
            this.ShowCancel = true;
        },
        CancelRequest() {
            this.ShowCancel = false;

        },
        Cancel() {
            this.ShowCancel = false;

        },
        formatCurrency(row) {
            let value = row.TotalInvoiceAmount;
            if (typeof value !== "number" && isNaN(value)) {
                return value;
            }

            var formatter = new Intl.NumberFormat('en-US');
            return formatter.format(value);
            //return value;
        },

        tabChange() {
             this.fields = this.buildColumns();
            this.applyFilter();
        },
        applyFilter() {
            this.currentPage = 1;
            if (this.InsideIndex == 1) {
                this.FilterList.Status = [];
                this.FilterList.Status.push(IntercompanyWorkflowStates.ApproveLevel1);
                this.FilterList.Status.push(IntercompanyWorkflowStates.ApproveLevel2);
            } else if (this.InsideIndex == 2) {
                this.FilterList.Status = [];
                this.FilterList.Status.push(IntercompanyWorkflowStates.Final);
            } else {
                this.FilterList.Status = [];
            }
            this.currentPage = 1;
            this.GetRequests();
        },
        formatRequestor(email) {
          return formatRequestor(email)
        },
        formatHFMCode(HFMCode) {
            let masterClientCode = "";
            if (HFMCode) {
                let masterClientCodeExists = (HFMCode.match(/-/g) || []).length;
                if (masterClientCodeExists > 1) {
                    masterClientCode = HFMCode.substring(0, HFMCode.indexOf("-"));
                } else {
                    let customEntity = HFMCode.split('–');
                    if (customEntity.length > 1) {
                        masterClientCode = customEntity[1];
                    }
                }
            }
            return masterClientCode;
        },
        IsValidDate(completionDate) {
            return (
                completionDate != null &&
                moment(completionDate.startDate).isValid() &&
                moment(completionDate.endDate).isValid()
            );
        },
        formatRequested(row) {
            if (row.RequestType == "Intercompany") {
                if (row.Name) {
                     if(row.CompletedValue !="")
                     {
                         return `Requested Code: ${row.RequestedValue} | Completed Code  : ${row.CompletedValue}`;
                     }
                     else
                     {
                         return `Requested Code: ${row.RequestedValue}`;
                     }
                }
            }
        },
        formatName(row) {
            if (row.RequestType == "Intercompany") {
                return `${row.Name}`;
            }  else {
                return "";
            }
        },

        formatBillingParty(row) {
            if (row.BillingParty) {
                return row.BillingParty;
            } else {
                return '-';
            }
        },
        formatStatus(status) {
            if (this.OutsideIndex == 1) {
                return "Draft";
            } else if (status != null && status != '') {
                if (status == "Final" || status == "Done" || status == "Complete" || status=="Completed") {
                    return "Completed";
                } else if (status == "Cancel" || status== "Rejected") {
                    return "Rejected";
                } else {
                     return `Pending ${status}`;
                }
            } else {
                return '-';
            }
        },
        onFiltered(e) {
            if ((e != null && e.length > 2) || e == null || e.length == 0) {
                this.currentPage = 1;
                this.GetRequests();
            }
        },
        sortChange(e) {
            this.sortBy = e.sortBy;
            this.sortDesc = e.sortDesc;
            this.GetRequests();
        },
        GetGridOptions() {
            let extrafilters = [];
            if (
                this.FilterList.CompletionDate != null &&
                this.FilterList.CompletionDate.hasOwnProperty("startDate") &&
                this.FilterList.CompletionDate.startDate != null
            ) {
                extrafilters.push({
                    Field: "CompletionDate",
                    Operator: "between",
                    Value: JSON.stringify(this.FilterList.CompletionDate),
                });
            }

            if (
                this.FilterList.Status != null && this.FilterList.Status != ""
            ) {
                extrafilters.push({
                    Field: "Status",
                    Operator: "=",
                    Value: this.FilterList.Status.join(","),
                });
            }
            return {
                PageSize: parseInt(this.perPage),
                CurrentPageNumber: parseInt(this.currentPage),
                FilterValue: this.filter,
                SortBy: this.sortBy,
                SortDesc: this.sortDesc,
                Filters: extrafilters,
                Status:parseInt(this.InsideIndex),
                IsDraftType: this.OutsideIndex==1
            };
        },
        buildColumns() {
            let columns = [];
            if (this.OutsideIndex != 1) {
                columns.push({
                    key: "Actions",
                    label: "Actions",
                });
            }
            columns.push({
                key: "UnlockMediaType",
                label: "UnlockMediaType",
                visible: false,
                thClass: "d-none",
                tdClass: "d-none",
            })
            columns.push({
                key: "Name",
                label: "Request",
                sortable: true,
                class: "NameHoverclass",
            })
            columns.push({
                key: "Requestor",
                label: "Requestor",
                sortable: true,
                class: "NameHoverclass",
            })
            columns.push({
                key: "RequestType",
                label: "Request Type",
                sortable: false,
            })
            columns.push({
                key: "Status",
                label: "Status",
                sortable: true,
            })
            columns.push({
                key: "CreatedDate",
                label: "Requested Date",
                sortable: true,
            })
            columns.push({
                key: "CompletionDate",
                label: "Completed Date",
                sortable: true,
            });



            if (this.allRequest && this.InsideIndex != 3) {
                columns.push({
                    key: "CompletioDate",
                    label: "Date Completed",
                    sortable: true,
                    align: 'center'
                });
            }
            return columns;
        },
        downloadAsExcel() {
            this.$store
                .dispatch("client/DownloadList", this.GetGridOptions())
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Requests_${moment().format("MMDDYYYYmmhhss")}.xlsx`;
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error downloading requests.`
                    );
                });
        },
        closeAudit() {
            this.closeAuditModal = true;
        },
        showAuditTrail(id, requestType) {
                this.requestId = id;
                this.requestType = requestType;
                this.closeAuditModal = false;
        },
        workflowAccessIssueAlert() {
            this.$store.dispatch(
                "progress/setErrorMessage",
                `Don't have permission to approve the request`
            );
        },
        redirectToReview(id) {
            this.$router.push({
                name: `WireTransferReview`,
                params: {
                    id: parseInt(id),
                },
            });
        },

        redirectToICView(id, isDraft) {
            this.$router.push({
                name: `InterCompany Agency`,
                params: {
                    requestid: parseInt(id),
                    isDraft: isDraft != undefined ? isDraft : false
                },
            });
        },
        navigateToEdit(id,requestType, status) {

             if (this.OutsideIndex == 1) {
                if (requestType == "Client") {
                    this.$router.push({
                        name: `Client Info`,
                        params: {
                            id: parseInt(id),
                            isDraft: true
                        },
                    });
                } else if (requestType == "Intercompany") {
                    this.redirectToICView(id, true);

                } else if (requestType == "Product") {
                    this.redirectToProductView(id);
                }
            }
            else
               {
           if(requestType=="Intercompany")
           {
               if(status ==this.OmniflowStatus.ICApproveLevel1)
               {
                  if (this.canApproveICRequestLevel1) {
                            this.$router.push({
                                path: `/IC/ICApproveLevel1/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
               }
               else if (status == this.OmniflowStatus.ICApproveLevel2) {
                        if (this.canApproveICRequestLevel2) {
                            this.$router.push({
                                path: `/IC/ICApproveLevel2/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == this.OmniflowStatus.ICApproveLevel3) {
                        if (this.canApproveICRequestLevel3) {
                            this.$router.push({
                                path: `/IC/Complete/${id}`
                            });
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to approve the request`
                            );
                        }
                    } else if (status == this.OmniflowStatus.Rejected) {
                        if (this.CanCreateICRequest) {
                            this.redirectToICView(id);
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Don't have permission to edit the request`
                            );
                        }
                    }
                    else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Request is completed or not started`
                        );
                    }
           }
               }
        },

        GetRequests() {
            this.TotalRows = 0;
            this.data.rows = [];
            this.$store
             .dispatch("client/GetAllInterCompanyAgencies",this.GetGridOptions())
                .then((res) => {
                    if (res.data) {
                        window.console.log(JSON.stringify(res.data.Result));
                        this.TotalRows = res.data.Total;
                        this.data.rows = res.data.Result;
                        this.RecordsPerPageOption = UpdateRecordsPerPageOption(this.TotalRows);
                    }
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error fetching requests.${ex}`
                        );
                    }
                });
        },
        FilterQuery: function () {
            this.currentPage = 1;
            this.GetRequests();
        },

        OnChange: function (val) {
            alert(val);
        },

        select: function (val) {
            alert(val);
        },
        RestValues: function () {
            this.$set(this.FilterList.CompletionDate, "startDate", null);
            this.$set(this.FilterList.CompletionDate, "endDate", null);
            this.FilterList.Status = [];
            this.currentPage = 1;
            this.GetRequests();
        },
        closefilter() {
            this.theme = "row";
            this.ShowFilter = false;
        },
        openfilter() {
            (this.theme = "col-md-9"), (this.ShowFilter = true);
        },
        createNew(name) {
            this.$router.push(name);
        },
        getWindowWidth: function () {
            this.getFixed = document.documentElement.clientWidth > 992 ? true : null;
        },
        getBackgroundColor: function (status) {
            window.console.log(status);
            let color = "#8c8c8c";
            let emptyStatus = '';
            let emptyStatusNull = null;
            switch (status) {
                 case IntercompanyWorkflowStates.ApproveLevel1:
                    color = "#efa56a";
                    break;

                case IntercompanyWorkflowStates.ApproveLevel2:
                    color = "#efa56a";
                    break;

                case IntercompanyWorkflowStates.Canceled:
                    color = "#df5678";
                    break;

                case IntercompanyWorkflowStates.AxUpdateTheRequestWithSystemCode:
                    color = "#efa56a";
                    break;

                case IntercompanyWorkflowStates.Final:
                    color = "#2cc4ad";
                    break;

                case emptyStatus:
                    color = "";
                    break;

                case emptyStatusNull:
                    color = "";
                    break;

                default:
                    color = "#ccc";
                    break;
            }

            return {
                color: color,
                "font-weight": 600
            };
        },
    },
};
</script>
