<template>
    <div style="padding-top: 20px">
        <div class="d-block row">
            <div class="col-lg-12">
                <b-tabs class="well" v-model="listIndex" v-if="canLoadTab">
                    <b-tab title="Client/Product" @click="changeTab('ClientProduct')" :active="isActiveTab('ClientProduct')" class="text-left list-tab" v-if="ClientTabAccess" lazy>
                        <keep-alive>
                            <request-table :index="0" :allRequest="true"></request-table>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Intercompany Agencies" @click="changeTab('IntercompanyAgencies')" :active="isActiveTab('IntercompanyAgencies')" class="text-left list-tab" v-if="ICTabAccess" lazy>
                        <keep-alive>
                            <intercompany-agencie :index="listIndex" :allRequest="false"></intercompany-agencie>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Wire Transfers" @click="changeTab('WireTransfers')" :active="isActiveTab('WireTransfers')" class="text-left list-tab" v-if="WireTabAccess" lazy>
                        <keep-alive>
                            <media-wire-transfer-table :index="listIndex" :allRequest="false"></media-wire-transfer-table>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Vendor Requests" @click="changeTab('VendorRequests')" :active="isActiveTab('VendorRequests')" class="text-left list-tab" v-if="isVendorAccess" lazy>
                        <keep-alive>
                            <vendor-request-table :index="listIndex" :allRequest="false"></vendor-request-table>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Profile Unlock" @click="changeTab('ProfileUnlock')" :active="isActiveTab('ProfileUnlock')" class="text-left list-tab" v-if="canAccessProfileUnlockTab" lazy>
                        <keep-alive>
                            <profile-unlock-table :index="listIndex" :allRequest="false"></profile-unlock-table>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Protego Users and Roles Report" @click="changeTab('ProtegoUsersandRolesReport')" :active="isActiveTab('ProtegoUsersandRolesReport')" class="text-left list-tab" v-if="UserRolesTabAccess" lazy>
                        <keep-alive>
                            <users-roles-report :index="listIndex" :allRequest="false"></users-roles-report>
                        </keep-alive>
                    </b-tab>
                    <b-tab title="Other Forms" @click="changeTab('OtherForms')" :active="isActiveTab('OtherForms')" class="text-left list-tab" v-if="OtherFormsAccess" lazy>
                        <keep-alive>
                            <other-forms :index="listIndex" :allRequest="false"></other-forms>
                        </keep-alive>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<style scoped>
.list-tab {
    font-size: 14px;
    color: #00a1d2 !important
}

.tooltip-inner {
    background-color: #f7f7f9 !important;
    color: #ffffff !important;
}

.tooltip-inner::before {
    border-top-color: #f7f7f9 !important;
    border-bottom-color: #f7f7f9 !important;
    border-left-color: #f7f7f9 !important;
    border-right-color: #f7f7f9 !important;
}

a.edit-link {
    color: #00a1d2 !important;
}

.popover-body>div>div:first-child>div {
    margin-top: 15px !important;
}

.popover-body>div>div:not(:first-child):not(:last-child)>div {
    margin-top: 20px !important;
}

.popover-body>div>div:last-child>div {
    margin-top: 20px !important;
    margin-bottom: 15px !important;
}

.popover-body>div div.left {
    height: 12px !important;
}

.form-control[placeholder="Search"] {
    background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
}

e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #00a1d2 !important;
    border-color: transparent !important;
    color: #fff !important;
}

.e-multi-select-list-wrapper .e-selectall-parent {
    margin-left: 40px !important;
}

.back-arrow:after {
    content: " \00AB";
    width: 4px;
    height: 7px;
}

.btn-link {
    color: #00a1d2 !important;
}

.All-Requests {
    width: 98px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.popovertext {
    width: 120px;
    height: 17px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.container2 p svg:hover {
    fill: #00a1d2 !important;
    color: #00a1d2 !important;
}

.container2 p:hover {
    color: #00a1d2 !important;
    fill: #00a1d2 !important;
}

.iconDetails {
    margin-left: -28%;
    float: left;
    height: 14px;
    width: 40px;
}

.container2 {
    width: 270px;
    min-height: 25px;
    float: left;
}

.left {
    float: left;
    width: 45px;
}

.right {
    margin-left: -23px;
    float: left;
    width: 149px;
}

.REQUEST-TYPE {
    margin-bottom: 12px;
    margin-left: -14px;
    width: 70px;
    height: 8px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
}

/* .Reset {
      margin-left:17px;
      height: 19px;
      font-family: 'Open Sans';
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.44px;
      color: #88d5ec;
    } */

.Apply {
    margin-left: 17px;
    height: 19px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #88d5ec;
    border-radius: 25px !important;
}

.Apply:hover {
    text-decoration: none !important;
    color: white !important;
    background-color: #018db8 !important;
    border-radius: 25px !important;
}

.Filters {
    width: 49px;
    height: 24px;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.All-Requests {
    width: 123px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.Container {
    width: 1268px;
    height: 353px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffffff;
}

.b-popover p {
    cursor: pointer !important;
}

.tooltip-inner {
    background-color: #2c3865 !important;
}

#body-container>.container {
    padding-top: 30px !important;
}

.nav-tabs .nav-link.active {
    border-bottom: 2px solid #00a8d6 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.btn-info:hover {
    color: #fff;
    background-color: #1388b7 !important;
}

.status-progress {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: inline-block;
}

.card {
    border: 0px !important;
}

.filter-icon {
    background-image: url("../assets/Icons/f.png");
    background-repeat: no-repeat;
    vertical-align: super;
}

.display-hover {
    height: 20px;
    width: 20px;
    text-align: center;
}

tbody tr:not(:hover) .display-hover>img.action-icons-hover,
tbody tr:not(:hover) .display-hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:not(:hover)>img.action-icons-hover,
tbody tr:hover .display-hover:hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:hover>img.action-icons-hover,
tbody tr:hover .display-hover:not(:hover)>img.action-icons {
    display: inline-block;
}

tbody tr>td {
    height: 40px !important;
    cursor: default !important;
}

td.NameHoverclass:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

td.NameHoverclass {
    overflow-wrap: anywhere !important;
    /* text-decoration: underline !important; */
}

#cardheader .card-header {
    background-color: rgba(127, 137, 152, 1) !important;
}

tbody {
    background-color: white !important;
}

.dataTables_length {
    display: none !important;
}

thead {
    background-color: rgba(123, 129, 136, 1) !important;
}

/* .nav-tabs {
      border-bottom: 1px solid white !important;
    } */

.tabs {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

/* .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      background-color: rgba(165, 177, 192, 1) !important;
    } */
/* .nav-tabs .nav-link.active {
      border-color: rgb(0, 168, 214) !important;
      border-bottom: solid rgb(0, 168, 214) 2px !important;
    } */
.nav-tabs .nav-link {
    /* border-bottom:1px solid transparent !important;  */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

/* a:not(.page-link) {
      font-size: 14px !important;
      font-family: Roboto !important;
      font-weight: bold !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      letter-spacing: 0.13px !important;
      color: #515151 !important;
    } */

.nav .nav-tabs {
    border: none !important;
}

.tab-content {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
    padding: 20px !important;
}

/* .nav{
         background-color: #ededed !important;

     } */

.vbt-per-page-dropdown {
    display: none !important;
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: calc(100% - 31%) !important;
    }
}

.close {
    outline: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group:not(.e-float-icon-left) {
    border: 1px solid #ced4da !important;
    width: 290px !important;
}

.NOTIFY-DISCREPANCY {
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: right;
    color: #00a1d2;
}

.NOTIFY-DISCREPANCYHEADER {
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: left;
    color: #00a1d2;
}

.popoverhover {
    font-family: "Open Sans";
    font-size: 12px;
}

.clientnamehover :hover {
    text-decoration: underline !important;
}

.omniipclass {
    font-family: "Open Sans" !important;
}
</style>

<script>
import { mapGetters , mapActions} from 'vuex';
import RequestTable from "./RequestTable";
import MediaWireTransferRequestTable from "./MediaWireTransferRequestTable";
import ProfileUnlockRequestTable from "./ProfileUnlockTable";
import VendorRequests from "./VendorRequestTable";
import InterCompanyAgencies from "./InterCompanyAgencieTable";
import UsersRolesReport from './UsersRolesReport.vue';
import OtherForms from './OtherForms.vue';
import {ADCountries} from "../utilities/CommonConst";

export default {
    components: {
        "request-table": RequestTable,
        "media-wire-transfer-table": MediaWireTransferRequestTable,
        "profile-unlock-table": ProfileUnlockRequestTable,
        "intercompany-agencie": InterCompanyAgencies,
        "vendor-request-table": VendorRequests,
        "users-roles-report": UsersRolesReport,
        "other-forms": OtherForms


    },
    data() {
        return {
            listIndex: 0,
            canLoadTab: false,
            userEmail: null,
            reachJobTitle: [],
            tabs: [
                {
                    name: 'Client / Product',
                    active: false
                },
                {
                    name: 'Intercompany Agencies',
                    active: false
                },
                {
                    name: 'Wire Transfer',
                    active: false
                },
                {
                    name: 'Vendor Request',
                    active: false
                },
                {
                    name: 'Profile Unlock',
                    active: true
                },
                {
                    name: 'Profile Unlock1',
                    active: false
                },
            ],
            DynamicTabs: [],
            isProfileAccess: false,
            isVendorAccess: false,
            dropDownValue: null,
            theme: "row",
            ShowFilter: false,
            RequestTypeFields: {
                text: "RequestType",
                value: "Id"
            },
            RequestStatusFields: {
                text: "RequestSts",
                value: "Id"
            },
            MediaTypeField: {
                text: "MediaTypeVal",
                value: "Id"
            },
            getFixed: true,
        };
    },
    async created() {
        await this.getEmailFromStore();
        await this.getAdEmpDetails();
        await this.getReachDetails();
        if (this.ClientTabAccess && this.ICTabAccess && this.WireTabAccess) {
            this.DynamicTabs.push('Client', 'IC', 'Wire');
        }
        window.console.log(JSON.stringify(this.DynamicTabs));
        console.log(this.canAccessVendorTab, "created")
        console.log(this.userEmail, "created");
        this.canLoadTab = true;
        if(
        (!this.ClientTabAccess && this.currentTab == "ClientProduct") ||
        (!this.ICTabAccess && this.currentTab == "IntercompanyAgencies") ||
        (!this.WireTabAccess && this.currentTab == "WireTransfers") ||
        (!this.isVendorAccess && this.currentTab == "VendorRequests") ||
        (!this.canAccessProfileUnlockTab && this.currentTab == "ProfileUnlock") ||
        (!this.UserRolesTabAccess && this.currentTab == "ProtegoUsersandRolesReport") ||
        (!this.OtherFormsAccess && this.currentTab == "OtherForms"))
        {
            if(this.ClientTabAccess) {
                this.$router.push("/ClientProduct");
                this.setCurrentTab("ClientProduct")
            }
            else if(!this.ClientTabAccess && this.ICTabAccess) {
                this.$router.push("/IntercompanyAgencies");
                this.setCurrentTab("IntercompanyAgencies")
            }
            else if(!this.ICTabAccess && this.WireTabAccess) {
                this.$router.push("/WireTransfers");
                this.setCurrentTab("WireTransfers")
            }
            else if(!this.WireTabAccess && this.isVendorAccess) {
                this.$router.push("/VendorRequests");
                this.setCurrentTab("VendorRequests")
            }
            else if(!this.isVendorAccess && this.canAccessProfileUnlockTab) {
                this.$router.push("/ProfileUnlock");
                this.setCurrentTab("ProfileUnlock")
            }
            else if(!this.canAccessProfileUnlockTab && this.UserRolesTabAccess) {
                this.$router.push("/ProtegoUsersandRolesReport");
                this.setCurrentTab("ProtegoUsersandRolesReport")
            }
            else if(!this.UserRolesTabAccess && this.OtherFormsAccess) {
                this.$router.push("/OtherForms");
                this.setCurrentTab("OtherForms")
            }
        }
    },
    async mounted() {
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);
        });
        console.log(this.canAccessVendorTab, "mounted")
        console.log(this.userEmail, "mounted");
    },
    computed: {
        ...mapGetters({currentTab: 'getCurrentTab'}),
        //Access Tab conditions for Client,IC,Wire based on protego permissions//
        ClientTabAccess() {
            return this.canAccessCientProductList;
        },
        ICTabAccess() {
            return this.canAccessICList;
        },
        WireTabAccess() {
            return this.canAccessWiretransferList;
        },
        UserRolesTabAccess() {
            return this.canAccessUserRolesList;
        },
        OtherFormsAccess() {
            return this.canAccessOtherForms;
        },
        //Access Tab conditions for Client,IC,Wire based on protego permissions//

        CanAccessList() {
            return this.$store.getters.canAccessList;
        },
        HasApproverPermissions() {
            return (
                this.canApproveClientRequestLevel1 ||
                this.canApproveClientRequestLevel2 ||
                this.canApproveClientRequestLevel3IC
            );
        },
        HasCodeCompletionPermissions() {
            return (
                this.canUpdateClientRequestCode
            );
        },
        HasAccessToAnyRequests() {
            return this.CanCreateClientRequest;
        },
        CanCreateClientRequest() {
            return this.$store.getters.canCreateClient;
        },
        canAccessAnyTabs() {
            return this.canAccessCientProductList || this.canAccessICList || this.canAccessWiretransferList || this.canAccessProfileUnlockTab || this.canAccessVendorTab || this.canAccessVendorTabUser || this.canAccessUserRolesList || this.canAccessOtherForms;
        },
        canAccessCientProductList() {
            return this.$store.getters.canAccessClientProductList;
        },
        canAccessUserRolesList() {
            return false; 
            //return this.$store.getters.canAccessUserRolesList;
        },
        canAccessOtherForms() {
            return false; 
            //return this.$store.getters.canAccessOtherForms;
        },
        canAccessICList() {
            return this.$store.getters.canAccessICList;
        },
        canAccessWiretransferList() {
            return false; 
            //return this.$store.getters.canAccessMediaTab;
        },
        canAccessVendorTab() {
            return this.$store.getters.canAccessVendorList;
        },
        canAccessVendorTabUser() {
            return this.isVendorAccess;
        },
        canApproveClientRequestLevel1() {
            return this.$store.getters.canApproveClientRequestLevel1;
        },
        canApproveClientRequestLevel2() {
            return this.$store.getters.canApproveClientRequestLevel2;
        },
        canApproveClientRequestLevel3IC() {
            return this.$store.getters.canApproveClientRequestLevel3IC;
        },
        canCompleteClientRequest() {
            return this.$store.getters.canCompleteClientRequest;
        },
        canUpdateClientRequestCode() {
            return this.$store.getters.canUpdateClientRequestCode;
        },
        canReadProfileRequest() {
            return this.$store.getters.canReadProfileRequest;
        },
        canAccessProfileRequest() {
            return this.$store.getters.canAccessProfileRequest;
        },
        canAccessProfileUnlockTab() {
            return (this.canAccessProfileRequest) || (this.isProfileAccess);
        },
    },

    methods: {
        ...mapActions({
            setCurrentTab: 'setCurrentTab'
        }),
        changeTab(tabName) {
            const newPath = `/${tabName}`;
            this.$router.push(newPath);
            this.setCurrentTab(tabName);
        },
        isActiveTab(tabName) {
            return this.currentTab === tabName;
        },
        async getEmailFromStore() {
            try {
                const resEmailData = await this.$store.dispatch("getEmail");
                this.userEmail = resEmailData.toLowerCase();
                console.log("Email", this.userEmail);
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        checkAccess() {
            if (!this.canAccessAnyTabs) {
                this.$router.push({
                    name: "unauthorized",
                    params: {
                        message: "UnAuthorized"
                    },
                });
            }
        },
        async getAdEmpDetails() {
            try {
                const resCountryData = await this.$store.dispatch("client/GetADEmpDetails", "");
                if (resCountryData.data.Country != null && ADCountries.some(country => country.toLowerCase() == resCountryData.data.Country.toLowerCase())) {
                    this.isProfileAccess = true;
                } else {
                    this.isProfileAccess = false;
                }
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
            }
        },
        async getReachDetails() {
            try {
                const resReachData = await this.$store.dispatch("product/GetReachUser", this.userEmail);
                const reachEmailData = resReachData.data.MailID;

                console.log(resReachData, "resReachData")
                console.log(reachEmailData, "reachEmailData")
                console.log(this.canAccessVendorTab, "canAccessVendorTab")

                if (this.canAccessVendorTab || reachEmailData || this.isProfileAccess) {
                    this.isVendorAccess = true;
                } else {
                    this.isVendorAccess = false;
                }
                this.checkAccess();
            } catch (ex) {
                if (ex.response) {
                    this.$store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                }
                this.checkAccess();
            }
        },
        downloadAsExcel() {

        },
        navigateToEdit(id, requestType) {
            if (requestType == "Client") {
                this.$router.push({
                    path: "Client/ClientInfo",
                    query: {
                        id: id
                    }
                });
            }
        },
        GetRequestsWithPaginationFilter() { },
        FilterQuery: function () {
            let RequestType = this.$refs.dropdownObjRequestTypes.ej2Instances.value;
            let StatustType = this.$refs.dropdownObjStatusType.ej2Instances.value;
            let MediaType = this.$refs.dropdownObjMediaType.ej2Instances.value;
            return RequestType + StatustType + MediaType;
        },

        OnChange: function (val) {
            alert(val);
        },

        select: function (val) {
            alert(val);
        },
        closefilter() {
            this.theme = "row";
            this.ShowFilter = false;
        },
        openfilter() {
            (this.theme = "col-md-9"), (this.ShowFilter = true);
        },
        createNew(name) {
            this.$router.push(name);
        },
        gotopageClient: function (val) {
            var data = val;
            window.console.log(data);
        },
        getWindowWidth: function () {
            this.getFixed = document.documentElement.clientWidth > 992 ? true : null;
        },
    },
};
</script>
