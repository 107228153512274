import {
    mapGetters
} from "vuex";

const tabtable = {
    data() {
        return {
            closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
            openFilterIcon: require("@/assets/Icons/open-filter-icon.svg"),
            InsideIndex: "0",
            OutsideIndex: 0,
            TotalRows: 0,
            filter: null,
            sortBy: "CreatedDate",
            sortDesc: true,
            ShowFilter: false,
            perPage: 10,
            currentPage: 1, fields: [],
            data: {
                config: {
                    card_mode: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    pagination_info: true,
                    global_search: {
                        visibility: false,
                    },
                },
                loading: false,
                classes: {
                    tableWrapper: "outer-table-div-class wrapper-class-two grid-table",
                    table: {
                        "table-sm": true,
                    },
                },
                rows: [],
            },
             FilterTabOptions: [{
                label: 'All Requests',
                value: "0"
            },
            {
                label: 'Completed',
                value: "2"
            },
            {
                label: 'Pending',
                value: "1"
            },
            ],
        }
    },
    watch: {
        InsideIndex: function () {
            this.fields = this.buildColumns();
            this.GetRequests();
        },
        currentPage: function (value) {
            if (value != null && parseInt(value) > 0) {
                this.GetRequests();
            }
        },
    },
    computed: {
        IsPaginationRequired() {
            return this.TotalRows != null && parseInt(this.TotalRows) > parseInt(this.perPage);
        },
        ...mapGetters({
            loggedInUser: "emailAddress"
        }),
    },
    methods: {
        onFilterChange() {
            this.GetRequests();
        }, tabChange() {
            this.fields = this.buildColumns();
            this.applyFilter();
        }, applyFilter() {
            this.currentPage = 1;
            this.GetRequests();
        }, sortChange(e) {
            this.sortBy = e.sortBy;
            this.sortDesc = e.sortDesc;
            this.GetRequests();
        }, GetRequests() {
            this.TotalRows = 0;
            this.data.rows = [];
            let moduleReq = `${this.ModuleName}/GetRequests`;
            this.$store
                .dispatch(moduleReq, this.GetGridOptions())
                .then((res) => {
                    if (res.data) {
                        this.TotalRows = res.data.Total;
                        this.data.rows = res.data.Result;
                    }
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error fetching requests.${ex}`
                        );
                    }
                });
        }, FilterQuery: function () {
            this.currentPage = 1;
            this.GetRequests();
        }, closefilter() {
            this.theme = "row";
            this.ShowFilter = false;
        }, openfilter() {
            this.ShowFilter = true;
        },
    }
};
export default tabtable;