<template>
    <div style="padding-top: 35px">
        <div class="d-block row">
            <div class="col-lg-12">
                <div class="row" style="margin-top: -48px !important; margin-bottom: 10px !important;margin-left:-64px !important; ">
                    <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="OutsideIndex" @input="tabChange()">
                        <b-tab title="Requests"> </b-tab>
                        <b-tab title="Drafts"> </b-tab>
                        <!-- <b-tab title="Completed"> </b-tab>
                        <b-tab title="Drafts"> </b-tab> -->
                    </b-tabs>
                </div>
                <div class="row" v-if="OutsideIndex==0" :style="{
                    'margin-bottom': index != 0 ? '10px' : 'auto',
                }">
                    <button style="margin-left: -12px !important" class="btn btn-link text-uppercase Open-Filter" @click="openfilter()" v-show="!ShowFilter">
                        <b-img width="11" height="9" :src="openFilterIcon" /> Open Filter
                    </button>
                    <div class="offset-lg-3" v-show="ShowFilter">
                        <button style="margin-left: -12px !important" class="btn btn-link text-uppercase Close-Filter" @click="closefilter()">
                            <b-img width="11" height="9" :src="closeFilterIcon" /> Close
                            Filter
                        </button>
                    </div>
                </div>

                <div class="row mb-3" v-if="OutsideIndex==0">
                    <div v-bind:class="[
                        {
                            'col-md-6 ': !ShowFilter,
                            'col-md-5 offset-md-3': ShowFilter,
                        },
                    ]">
                        <div class="row">
                            <div class="col-lg-3" :class="{ 'col-lg-4': ShowFilter }">
                                <OmniSelect v-model="InsideIndex" :options="FilterTabOptions" name="" :showLabel="false" placeholder="" :showSearch="false" />

                            </div>
                            <div class="col-lg-6">
                                <input type="text" class="form-control" v-model="filter" v-on:keyup.enter="onFilterChange()" placeholder="Search" />

                            </div>

                        </div>

                    </div>
                    <div v-bind:class="[
                        {
                            'col-md-6 text-right ': !ShowFilter,
                            'col-md-4 text-right': ShowFilter,
                        },
                    ]">
<b-button class="btn custom-button" text="Download" variant="outline-primary" ref="popoverButton">
    Download
</b-button>
<b-popover placement="bottom" display="block" :target="$refs.popoverButton" :show.sync="show" variant="primary">
    <div class="text-left">
        <div>
            <p class="popoverhover" @click="downloadList('Excel');show = !show">
                CSV
            </p>
        </div>
        <div>
            <p class="popoverhover" @click="downloadList('Pdf');show = !show">
                PDF
            </p>
        </div>
    </div>
</b-popover>
<template>
<b-button class="btn custom-button btn-primary" id="create-wire-request" ref="templatePopoverButton" text="Create New" v-if="HasAccessToAnyRequests">
    CREATE WIRETRANSFER REQUESTS
</b-button>
<b-popover placement="top" :target="$refs.templatePopoverButton" :show.sync="showTemplateSubmenu" v-model="subMenuOpen" variant="primary" auto-close no-fade>
    <div class="text-left">
        <div class="container2">
            <div class="left"></div>
            <div class="right">
                <p class="popoverhover" @click="CreateRequest();showTemplateSubmenu = !showTemplateSubmenu">NEW REQUEST</p>
            </div>
        </div>
        <div class="container2">
            <div class="left"></div>
            <div class="right">
                <p class="popoverhover" @click="GetAllTemplatesData();showTemplateSubmenu = !showTemplateSubmenu">TEMPLATES</p>
            </div>
        </div>
    </div>
</b-popover>
</template>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3" v-if="ShowFilter">
                        <div>
                            <div class="form-group col-md-12">
                                <OmniSelect class="omniipclass" :options="PaymentTypeOptions" v-model="FilterList.PaymentType" name="Payment Type" placeholder="Filter by PaymentType" :showSearch="true" :showSelectAll="true" selectAllText="All" unSelectAllText="None" />
                            </div>
                            <div class="form-group col-md-12">
                                <OmniSelect class="omniipclass" :options="CompanyNameOptions" v-model="FilterList.CompanyName" name="Company" placeholder="Filter by Company" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" />
                            </div>
                            <div class="form-group col-md-12">
                                <OmniSelect class="omniipclass" :options="BeneficiaryOptions" v-model="FilterList.Beneficiary" name="Beneficiary" placeholder="Filter by Beneficiary" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" />
                            </div>
                            <div class="form-group col-md-12">
                                <OmniSelect class="omniipclass" :options="StatusOptions" v-model="FilterList.Status" name="STATUS" placeholder="Filter by Status" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" />

                            </div>
                            <div class="row text-right">
                                <div class="col-md-11 p-0">
                                    <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="RestValues()">
                                        RESET
                                    </button>
                                    <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="FilterQuery()">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-bind:class="[
                        { 'col-md-12 ': !ShowFilter, 'col-md-9': ShowFilter },
                    ]">
                        <b-table id="all-items" :per-page="perPage" :items="data.rows" :fields="fields" no-local-sorting=true @sort-changed="sortChange" show-empty striped hover responsive class="all-items">
                            <template v-slot:cell(ID)="data">

                                <a class="edit-link" @click="
                                    navigateToEdit(
                                        data.item.ID,
                                        data.item.Status,
                                        data.item
                                    )
                                    ">{{ (data.item.ID) }}</a>
                            </template>
                            <template v-slot:cell(CreatedDate)="data">
                                <div class="text-right">
                                    {{ data.value | formatDate }}
                                </div>
                            </template>
                            <template v-slot:cell(TotalInvoiceAmount)="data">
                                <div class="text-right">
                                    {{ formatCurrency(data.item) }}
                                </div>
                            </template>
                            <template v-slot:cell(IsPaymentApplied)="data">
                                <div class="text-right">
                                    <span>
                                        {{ data.value ? 'YES' : (data.value === false ? 'NO' : '')}}
                                    </span>
                                </div>
                            </template>
                            <template v-slot:cell(PaymentAmount)="data">
                                <div class="text-right">
                                    <span v-if="data.value != ''">
                                        {{ formatPaymentCurrency(data.item) }}
                                    </span>
                                </div>
                            </template>
                            <template v-slot:cell(Requestor)="data">
                                <div>
                                    {{ formatRequestor(data.value) }}
                                </div>
                            </template>
                            <template v-slot:cell(WireNo)="data">
                                <div class="d-flex justify-content-end align-items-center" style="font-weight:bold;">
                                    {{ data.value }}
                                    <b-img v-if="data.item.Status=='Approved' || data.item.IsReturn" v-b-tooltip.hover title="View History" :src="historyIcon"
                                    @click="ViewHistory(data.item)" class="Custom-Popup" />
                                    <b-img v-if="data.item.Status=='Approved' && canWatchWireTransferRequest && data.item.IsPaymentApplied == null" v-b-tooltip.hover title="Payment Applied" :src="paymentApplyIcon"
                                    @click="ViewPaymentApply(data.item)" class="Custom-Popup"/>
                                </div>
                            </template>
                            <template v-slot:cell(Actions)="row">
                                <div>
                                    <a class="sm">
                                        <b-img v-show="row.detailsShowing" class="collapse-grid" @click="row.toggleDetails" :src="expandIcon" />
                                        <b-img v-show="!row.detailsShowing" class="expand-grid" :src="collapseIcon" @click="row.toggleDetails();GetUploadedFile(row.item.ID);" />
                                    </a>
                                    <b-img v-if="ShowEdit(row.item)" v-b-tooltip.hover title="Edit Request" :src="editIcon" @click="EditRequest(row.item)" class="Custom-Edit" />
                                    <b-img v-if="row.item.Status=='Requested' && IsRequestor(row.item)" v-b-tooltip.hover title="Cancel Request" :src="deleteIcon" @click="DeleteRequest(row)" class="Custom-Delete" />
                                    <b-img v-if="OutsideIndex==1" v-b-tooltip.hover title="Edit Draft Request" :src="editIcon" @click="EditDraftRequest(row.item)" class="Custom-Edit" />

                                </div>

                            </template>
                            <template v-slot:row-details="row">
                                <div class="row row-details-container">
                                    <div class="col-lg-6 support-document">
                                        <div class="row-detail-title">Support Documents</div>
                                        <div class="row-detail-document" v-for="file in row.item.Files" :key="file.name">
                                            <b-img class="document-icon" :src="fileIcon" alt="Download"></b-img> <span :title="file.Name" class="document-name">{{file.Name}}</span>
                                            <b-img @click="downloadItem(file)" title="Download" :src="downloadIcon" alt="Download"></b-img>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="row-detail-title">Notes</div>
                                        <div class="row-details-notes">{{row.item.Notes}}</div>
                                    </div>
                                </div>
                            </template>
                            <template slot="custom-foot" v-if="IsPaginationRequired">
                                <b-tr>
                                    <b-td class="custom-pagination" colspan="13">
                                        <b-form-select class="records-per-page" v-model="perPage" :options="RecordsPerPageOption"></b-form-select>
                                        <Pagination style="float: right" :per-page="perPage" aria-controls="open-items" v-model="currentPage" :total-rows="TotalRows" first-number last-number />
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <component :is="currentAuditTrail" v-bind="{ id: requestId, closeAuditModal: closeAuditModal }" @closeAudit="closeAudit()"></component>
        <b-modal modal-class="cancel-request" dialog-class="cancel-request" v-model="ShowCancel" :no-close-on-backdrop="true" size="sm">
            <template v-slot:modal-header="{ close }">
                <h5 class="modal-title">Cancel wire transfer request</h5>
                <button class="close" @click="Cancel()"></button>
            </template>
            <div>
                <p class="cancel-info-text">
                    Your wire transfer request will be canceled and this action is permanent. Please provide a brief reason for the cancelation.
                </p>
                <div>
                    <b-textarea :placeholder="'Add details'" :rows="5" v-model="CancelNotes" class="">

                    </b-textarea>
                </div>

            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="text-right" v-if="ShowCancel==true">
                    <button class="btn custom-button btn-secondary" @click="Cancel()">
                        Never Mind
                    </button>
                    <button :disabled="ShowCancel==true&&$v.$invalid" class="btn custom-button btn-primary" @click="CancelRequest()">
                        Cancel Request
                    </button>

                </div>
            </template>
        </b-modal>
        <b-modal v-model="showModal" :no-close-on-backdrop="true" size="sm" hide-footer title="History">
            <template #modal-header>
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="modal-title">Wire Transfer History</h5>
                    <button class="close close-top-right" @click="closeWireHistory">X</button>
                </div>
            </template>
            <table class="table" ref="historyTable">
                <thead>
                    <tr>
                        <th>Wire Number</th>
                        <th>Created Date</th>
                        <th>Requestor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in historyitems" :key="index">
                        <td style="display:none">{{ item.ID }}</td>
                        <td style="display:none">{{ item.MediaWireTransferRequestID}}</td>
                        <td>{{ item.WireNo }}
                            <span v-if="index === 0">
                                <b-img v-if="canReturnRequest" v-b-tooltip.hover v-model="ReturnIcon"
                                  title="Return" :src="returnIcon"
                                  @click="returnWire"
                                  class="Custom-Popup" />
                            </span>
                        </td>
                        <td>{{ item.CreatedDate | formatDateAndTime }}</td>
                        <td>{{ item.UpdatedBy }}</td>

                    </tr>
                </tbody>
            </table>
        </b-modal>
        <b-modal v-model="showPaymentApplyModel" :no-close-on-backdrop="true" size="sm" hide-footer title="Apply Payment">
            <template #modal-header>
                <div class="d-flex justify-content-between align-items-center">
                    <h5 class="modal-title font-weight-bold">Payment Applied and USD Amount</h5>
                    <button class="close close-top-right" @click="closePaymentApplyModel">X</button>
                </div>
            </template>
            <div class="content-box row">
                <div class="col-12">
                    <label class="col-8 mb-3 pl-0" name="label">Payment Applied</label>
                    <b-form-radio-group v-model="IsPaymentApplied" id="PaymentApplied" name="Payment-Applied">
                        <b-form-radio value="true">YES</b-form-radio>
                        <b-form-radio value="false">NO</b-form-radio>
                    </b-form-radio-group>
                </div>
                <div class="mt-4 col-6">
                    <label class="col-8 mb-3 pl-0" name="label">USD Amount</label>
                    <b-form-input v-model="PaymentAmount" @blur="formatDollars()" autocomplete="off" id="PaymentAmount" class="form-control formatDollars" placeholder="USD Amount" style="height:40px"></b-form-input>
                </div>
            </div>
            <div class="form-group mt-3 mx-2 pt-3 border-top text-right">
                <button class="text-uppercase btn custom-button btn-primary" :disabled="$v.$invalid"  @click="ApplyPayment()">
                    APPLY
                </button>
                <button class="btn btn-link text-uppercase" @click="ClearApplyPayment()">
                    CANCEL
                </button>
            </div>
        </b-modal>
        <b-modal v-model="showTemplateDataModel" :no-close-on-backdrop="true" size="lg" hide-footer title="TemplateData">
  <template #modal-header>
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="modal-title">Template Data</h5>
      <button class="close close-top-right" @click="closeTemplateData">X</button>
    </div>
  </template>
  <table id="all-items" :per-page="templatePage" :items="templateData" class="table template-table scrollable-table" ref="TemplatesTable">
    <thead>
      <tr>
        <th>Template Name</th>
        <th>Description</th>
        <th>Created Date</th>
      </tr>
    </thead>
    <tbody v-if="templateData.length > 0">
      <tr v-for="(item, index) in paginatedData" :key="index">
        <td style="display:none">{{ item.ID }}</td>
        <td><a href="#" @click="GetSavedTemplate(item)">{{ item.TemplateName.replace(/"/g, '') }}</a></td>
        <td>{{ item.TemplateDescription.replace(/"/g, '') }}</td>
        <td>{{ item.CreatedDate | formatDateAndTime }}</td>
      </tr>
    </tbody>
  </table>
  <div v-if="templateData.length === 0" style="text-align: center; margin-top: 20px;">
    <p style="font-size: 18px;">There are no records to show</p>
  </div>
    <div class="custom-template-pagination  custom-pagination" v-if="IsTemplatePaginationRequired">
        <b-form-select class="records-per-page" v-model="templatePage" :options="TemplateRecordsPerPageOption"></b-form-select>
        <Pagination style="float: right" :per-page="templatePage" aria-controls="open-items" v-model="currentTemplatePage" :total-rows="totalTemplateRows" first-number last-number/>
    </div>
</b-modal>
        <b-modal v-model="showCommentModel" :no-close-on-backdrop="true" size="xs" hide-footer>
            <template #modal-header>
                <h7 class="modal-title">Enter Reason for Returning</h7>
                <button class="close" @click="closeCommentBox">X</button>
            </template>
            <b-textarea v-model="Comments" rows="5"> </b-textarea>
            <br />
            <div class="d-flex justify-content-end mt-2">
            <button @click="EditWire" :disabled="isSubmitDisabled" class="btn custom-button btn-primary">Submit</button>
        </div>
        </b-modal>
    </div>
</template>
<style scoped>
.custom-template-pagination.custom-pagination {
    bottom: 15px;
}
.template-table {
    margin-bottom:60px;
}
.content-box {
    max-width:760px;
    margin:auto;
}
.formatDollars {
    background-image: url("data:image/svg+xml;utf8,<svg \ xmlns='http://www.w3.org/2000/svg' \ version='1.1' height='40px' width='40px'>\ <text x='2' y='22' \ fill='gray' font-size='15'>$</text></svg>");
    background-repeat: no-repeat;
    background-position: 0 3px;
}
.content-box label {
    font-size: 12px;
}
.scrollable-table{
    max-height: 500px;
    overflow-y: auto;

}
.scrollable-table td , .table td {
    word-break: break-word;
}
.cancel-info-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #47505a;
}

.Custom-Edit {
    padding-left: 10px;
}

.Custom-Delete {
    padding-left: 10px;
}

.Custom-Popup {
    padding-left: 10px;
}

.row-details-container {
    background-color: #fff;
    border: 1px solid #ececec;
    margin-left: 40px;
    margin-top: 10px;
    min-height: 60px;
    margin-right: 40px;
    margin-bottom: 10px;
    padding-bottom: 20px;

}

.row-detail-title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
    padding: 10px 0px 0px 10px;
}

.document-icon {
    padding-left: 20px;
    height: 14px;
    /* width: 11px; */
    padding-right: 8px;
    vertical-align: baseline;
}

.document-name {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #47505a;
    width: 80%;
    /* text-overflow: revert; */
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    /* word-break: unset; */
    height: 10px;
    white-space: nowrap;
}

.row-details-container>.support-document:after {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    right: 0%;
    top: 10%;
    height: 105%;
    border-right: 1px solid #b7bcd6;
}

.row-details-notes {
    padding-left: 10px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.row-detail-document {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: normal;
    color: #47505a;
    padding: 10px 0px 0px 10px;
}

.list-tab {
    font-size: 14px;
    color: #00a1d2 !important;
}

.tooltip-inner {
    background-color: #f7f7f9 !important;
    color: #ffffff !important;
}

.tooltip-inner::before {
    border-top-color: #f7f7f9 !important;
    border-bottom-color: #f7f7f9 !important;
    border-left-color: #f7f7f9 !important;
    border-right-color: #f7f7f9 !important;
}

a.edit-link {
    color: #00a1d2 !important;
}

table.b-table tbody tr td {
    line-height: 1.2 !important;
}

.popover-body>div>div:first-child>div {
    margin-top: 15px !important;
}

.popover-body>div>div:not(:first-child):not(:last-child)>div {
    margin-top: 20px !important;
}

.popover-body>div>div:last-child>div {
    margin-top: 20px !important;
    margin-bottom: 15px !important;
}

.popover-body>div div.left {
    height: 12px !important;
}

.b-table tbody>tr {
    height: 40px !important;
}

.table td {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

table tfoot tr td {
    padding-right: 0px !important;
}

.form-control[placeholder="Search"] {
    background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
}

/* .form-control[placeholder="Search"]:placeholder-shown {
        position:relative;
        right:0rem;
        background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
    } */
/* .form-control[placeholder="Search"]::-webkit-search-cancel-button{
      -webkit-appearance: none;
        position:relative;
        right:-1.8rem;
    } */
e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #00a1d2 !important;
    border-color: transparent !important;
    color: #fff !important;
}

.e-multi-select-list-wrapper .e-selectall-parent {
    margin-left: 40px !important;
}

.back-arrow:after {
    content: " \00AB";
    width: 4px;
    height: 7px;
}

.btn-link {
    color: #00a1d2 !important;
}

.All-Requests {
    width: 98px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"],
.table.b-table>thead>tr>[aria-sort="ascending"],
.table.b-table>tfoot>tr>[aria-sort="ascending"],
.table.b-table>thead>tr>[aria-sort="descending"],
.table.b-table>tfoot>tr>[aria-sort="descending"] {
    background-position-y: 25px !important;
    background-position-x: 95% !important;
}

.container2:hover p span {
    filter: invert(45%) sepia(81%) saturate(3821%) hue-rotate(167deg) brightness(102%) contrast(101%);
}

.container2:hover p {
    fill: #00a1d2 !important;
    color: #00a1d2 !important;
}

.iconDetails {
    margin-left: -28%;
    float: left;
    height: 14px;
    width: 40px;
}

.container2 {
    width: 270px;
    min-height: 25px;
    float: left;
}

.left {
    float: left;
    width: 45px;
}

.right {
    margin-left: -23px;
    float: left;
    width: 149px;
}

.Apply {
    margin-left: 17px;
    height: 19px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #88d5ec;
    border-radius: 25px !important;
}

.Apply:hover {
    text-decoration: none !important;
    color: white !important;
    background-color: #018db8 !important;
    border-radius: 25px !important;
}

.Filters {
    width: 49px;
    height: 24px;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.Open-Filter {
    /* width: 74px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.All-Requests {
    width: 123px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.Open-Filter {
    padding-top: 16px;
    /* width: 123px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Close-Filter {
    margin-left: 15px;
    padding-top: 20px;
    width: 150px;
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Container {
    width: 1268px;
    height: 353px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffffff;
}

.b-popover p {
    cursor: pointer !important;
}

.tooltip-inner {
    background-color: #2c3865 !important;
}

#body-container>.container {
    padding-top: 30px !important;
}

.btn-info:hover {
    color: #fff;
    background-color: #1388b7 !important;
}

table>tr>td {
    height: 40px !important;

}

.icon-add-client {
    background-image: url("../assets/Icons/add-client.svg");
}

.icon-add-product {
    background-image: url("../assets/Icons/add-product.svg");
}

.icon-add-intercompany {
    background-image: url("../assets/Icons/add-intercompany.svg");
}

.icon-add-vendor {
    background-image: url("../assets/Icons/add-vendor.svg");
}

.icon-custom {
    height: 19px;
    width: 19px;
    background-repeat: no-repeat;
    display: inline-block;
}

.card {
    border: 0px !important;
}

.filter-icon {
    background-image: url("../assets/Icons/f.png");
    background-repeat: no-repeat;
    vertical-align: super;
}

.display-hover {
    height: 20px;
    width: 20px;
    text-align: center;
}

tbody tr:not(:hover) .display-hover>img.action-icons-hover,
tbody tr:not(:hover) .display-hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:not(:hover)>img.action-icons-hover,
tbody tr:hover .display-hover:hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:hover>img.action-icons-hover,
tbody tr:hover .display-hover:not(:hover)>img.action-icons {
    display: inline-block;
}

tbody tr>td {
    height: 40px !important;
    cursor: default !important;
}

tbody tr>td .audit-trail {
    cursor: pointer !important;
}

td.NameHoverclass:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

td.NameHoverclass {
    overflow-wrap: anywhere !important;
    /* text-decoration: underline !important; */
}

#cardheader .card-header {
    background-color: rgba(127, 137, 152, 1) !important;
}

tbody {
    background-color: white !important;
}

.dataTables_length {
    display: none !important;
}

thead {
    background-color: rgba(123, 129, 136, 1) !important;
}

/* .nav-tabs {
      border-bottom: 1px solid white !important;
    } */

.tabs {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.tab-content {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
    padding: 20px !important;
}

/* .input-group {
        display: none !important;
    } */

.vbt-per-page-dropdown {
    display: none !important;
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: calc(100% - 31%) !important;
    }
}

.close-top-right {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2.5rem;
    cursor: pointer;
}

.close {
    outline: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group:not(.e-float-icon-left) {
    border: 1px solid #ced4da !important;
    width: 290px !important;
}

.popoverhover {
    font-family: "Open Sans";
    font-size: 12px;
    margin: 16px;
}

.popoverhoverproduct {
    font-family: "Open Sans";
    font-size: 12px;
}

.clientnamehover :hover {
    text-decoration: underline !important;
}

.omniipclass {
    font-family: "Open Sans" !important;
}

.Open-Filter img,
.Close-Filter img {
    vertical-align: initial !important;
}
</style>

<script>
const moment = require("moment");
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import * as ClientWorkflowStates from "../utilities/ClientWorkflowStates";
import * as IntercompanyWorkflowStates from "../utilities/IntercompanyWorkflowStates";
import * as ProductWorkflowStates from "../utilities/ProductWorkflowStates";
import {formatRequestor} from "../utilities/Helper";
import {
    requiredIf,
    minLength
} from "vuelidate/lib/validators";

import service from "../services/Dropdowns";
import tabtable from "../mixins/tabtable";
import {
    mapGetters
} from 'vuex';
import {UpdateRecordsPerPageOption} from "./../utilities/paginationUtils";
export default {
    components: {
        DateRangePicker,
    },
    name: "MediaWireTransferRequestTable",
    mixins: [tabtable],
    data() {
        return {
            ApplyPaymentID: null,
            PaymentAmount:null,
            IsPaymentApplied:null,
            perPage: 10,
            templatePage: 10,
            RecordsPerPageOption: [],
            TemplateRecordsPerPageOption: [],
            currentPage: 1,
            currentTemplatePage: 1,
            canReturnRequest:false,
            requestor:null,
            requestType:null,
            templateId:null,
            latestWireId:null,
            Comments: '',
            WireTransferRequestID: null,
            selectedWireDetails: null,
            ModuleName: 'wiretransfer',
            BeneficiaryOptions: [],
            CompanyNameOptions: [],
            RequestFiles: [],
            historyitems: [],
            templateData:[],
            CancelIndex: -1,
            CancelNotes: null,
            ShowCancel: false,
            showModal: false,
            showPaymentApplyModel: false,
            showTemplateDataModel:false,
            showCommentModel: false,
            deleteIcon: require("@/assets/Icons/cancel_icon2.svg"),
            collapseIcon: require("@/assets/Icons/arrow-down.svg"),
            expandIcon: require("@/assets/Icons/rightarrow.svg"),
            editIcon: require("@/assets/Icons/edit.svg"),
            downloadIcon: require("@/assets/Icons/icon-download.svg"),
            fileIcon: require("@/assets/Icons/file-icon-web.webp"),
            historyIcon: require("@/assets/Icons/History.svg"),
            returnIcon: require("@/assets/Icons/Return.svg"),
            paymentApplyIcon: require("@/assets/Icons/payment-apply.svg"),
            PaymentTypeOptions: [{
                label: "House",
                value: "House"
            },
            {
                label: "Media",
                value: "Media"
            },
            {
                label: "Payroll",
                value: "Payroll"
            },
            {
                label: "Production",
                value: "Production"
            },
            ],
            StatusOptions: [{
                label: 'Approved',
                value: 'Approved'
            }, {
                label: 'Canceled',
                value: 'Canceled'
            }, {
                label: 'Denied',
                value: 'Denied'
            }, {
                label: 'In Review',
                value: 'In Review'
            }, {
                label: 'Requested',
                value: 'Requested'
            }],
            requestId: 0,
            closeAuditModal: true,
            auditIcon: require("@/assets/Icons/auditTrail_icon.svg"),
            OmniflowStatus: {
                ApprovLevel1: "Finance Approval",
                ApprovLevel2: "AR Approval",
                ApprovLevel3: "IC Approval",
                ApprovLevel4: "Open codes in AX and DDS",
                ApprovLevel5: "Update the request with System Code",
                Rejected: "Rejected",
                Completed: "Completed",
                ProductApproveLevel1: "Finance Approval",
                ProductApproveLevel2: "Complete Request",
                ICApproveLevel1: "Intercompany Approval",
                ICApproveLevel2: "Finance Approval",
                ICApproveLevel3: "D365 Approval",
            },
            FilterList: {
                PaymentType: null,
                CompanyName: null,
                Beneficiary: null,
                Status: null,
            },
            RequestTypes: [{
                value: "1",
                label: "Client"
            },
            {
                value: "2",
                label: "Product"
            },
            {
                value: "3",
                label: "Inter Company"
            },
            {
                value: "4",
                label: "Vendor"
            },
            ],
            RequestTypeFields: {
                text: "RequestType",
                value: "Id"
            },

            RequestStatus: [],
            RequestStatusFields: {
                text: "RequestSts",
                value: "Id"
            },
            MediaTypeField: {
                text: "MediaTypeVal",
                value: "Id"
            },
            getFixed: true,
            DateFormat: "MM/DD/YYYY",
            show: false,
            showTemplateSubmenu:false,
            fileType: null
        };
    },
    validations: {
        CancelNotes: {
            minLength: minLength(1),
            required: requiredIf(function () {
                return this.ShowCancel == true;
            })
        },
        PaymentAmount: {
            required: requiredIf(function () {
                return this.showPaymentApplyModel == true;
            })
        },
        IsPaymentApplied: {
            required: requiredIf(function () {
                return this.showPaymentApplyModel == true;
            })
        },

    },

    props: {
        allRequest: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    mounted() {
        this.formatDollars();
        if (this.CanAccessList) {
            this.GetBeneficiary();
            this.fields = this.buildColumns();
            this.GetRequests();
            this.LoadAgency();
        } else {
            this.$router.push({
                name: "unauthorized",
                params: {
                    message: "UnAuthorized"
                },
            });
        }
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);
        });
    },
    computed: {
        IsTemplatePaginationRequired() {
            return this.templateData.length != null && parseInt(this.templateData.length) > parseInt(this.templatePage);
        },
        IsFormFilterValid() {
            return this.FilterList.PaymentType != null || this.FilterList.CompanyName != null || this.FilterList.Beneficiary != null || this.FilterList.Status != null;
        },
        ...mapGetters({
            loggedInUser: "emailAddress"
        }),
        totalTemplateRows() {
            return this.templateData.length
        },
    paginatedData() {
      const startIndex = (this.currentTemplatePage - 1) * this.templatePage
      return this.templateData.slice(startIndex, startIndex + this.templatePage)
    },
        emplateId() {
    return this.$store.getters["wiretransfer/templateId"];
  },
        isSubmitDisabled() {
            return this.Comments.length === 0;
        },
        HasAccessToAnyRequests() {
            return this.$store.getters.canCreateWireTransferRequest || this.$store.getters.IsBPM || this.$store.getters.canSubmitPayrollWireTransferRequest;
        },
        HasCFOApproverPermission() {
            return this.$store.getters.canApproveLevelCFOWireTransferRequest;
        },
        currentAuditTrail() {
            return this.closeAuditModal != true ?
                this.requestType === "Client" ?
                    "client-audit" :
                    this.requestType === "Product" ?
                        "product-audit" :
                        this.requestType === "Intercompany" ?
                            "ic-audit" :
                            "" : "";
        },
        CanAccessList() {
            return this.$store.getters.canAccessList;
        },
        canApproveLevel1WireTransferRequest() {
            return this.$store.getters.canApproveLevel1WireTransferRequest;
        },
        userDataMapping() {
            return this.$store.getters.dataMappings;
        },
        AllAgencyAccess() {
            return this.userDataMapping != null && this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e == "*";
        },
        IsAgencyArray() {
            return this.userDataMapping != null && !this.AllAgencyAccess && Array.isArray(this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e);
        },
        canWatchWireTransferRequest() {
            return this.$store.getters.canWatchWireTransferRequest;
        },

    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
        },
        formatDateAndTime(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY h:mma") : "-";
        },

    },
    watch: {
        currentTemplatePage: function (value) {
            if (value != null && parseInt(value) > 0) {
                this.GetAllTemplatesData();
            }
        },
        templatePage: function () {
            this.currentTemplatePage = 1;
            this.GetAllTemplatesData();
        },
        perPage: function () {
            this.currentPage = 1;
            this.GetRequests();
        }
    },
    methods: {
        ApplyPayment() {
            this.$store
                .dispatch("wiretransfer/UpdatePaymentDetails", this.GetPaymentApplyData())
                .then(() => {
                    this.$router.push("/WireTransfers");
                    this.GetRequests()
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Don't have permission to return the request`
                        );
                    }
                });
            this.ClearApplyPayment()
        },
        ClearApplyPayment() {
            this.showPaymentApplyModel = false;
            this.IsPaymentApplied = null;
            this.PaymentAmount = '';
        },
        formatDollars() {
            const amt = this.PaymentAmount
            if (amt && amt  != '') {
                var num = amt.toString().replace(',', '');

                num = Number(num);
                if (!isNaN(num)) {
                    let temp = num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    this.PaymentAmount = temp;
                }
                else {
                    this.PaymentAmount = '';
                }
            }
        },
            GetTemplateGridOptions() {
                return {
                    PageSize: parseInt(this.templatePage),
                    CurrentPageNumber: parseInt(this.currentTemplatePage),
                    RequestType: "WireTransfer",
                    Requestor: this.loggedInUser.toString()
                };
            },
           EditWire() {

             this.$store
                .dispatch("wiretransfer/MarkAsReturn", this.GetHistoryData())
                .then(() => {
                    this.showModal = false;
                    this.showCommentModel = false;
                    this.GetRequests()
                    this.Comments = '';
                    this.$router.push("/WireTransfers");
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.showModal = false;
                        this.showCommentModel = false;
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Don't have permission to return the request`
                        );
                    }
                });
        },
        GetWireID()
        {
            return {
                id :this.WireTransferRequestID,
            };
        },
        GetHistoryData() {
            return {
               id :this.latestWireId,
                Comments: this.Comments,
                MediaWireTransferRequestID :this.WireTransferRequestID,
            };
        },
        GetPaymentApplyData() {
            const isPaymentApplied = this.IsPaymentApplied == "true" ? true : false;
            return {
                id :this.ApplyPaymentID,
                IsPaymentApplied: isPaymentApplied,
                PaymentAmount :parseFloat(this.PaymentAmount.toString().replace(/,/g, ""))
            };
        },
        GetTemplateID()
        {
            return {
               templateId :this.templateId,
            };
        },
        returnWire() {
            this.showCommentModel = true;
        },
        async ViewHistory(rowData) {
            if(rowData.Status=='Approved' && ((this.canApproveLevel1WireTransferRequest &&
            this.UserAgency(rowData.VendorName)) || this.IsCFOApprover() ||
            this.IsCFOApproverForInternational(rowData))) {
                this.canReturnRequest = true;
            }
            else {
                this.canReturnRequest = false;
            }
            this.$store
                .dispatch("wiretransfer/GetWireHistory", rowData.ID)
                .then(response => {
                    const historyData = response.data;
                    this.historyitems = historyData;
                    this.showModal = true;
                    this.latestWireId=this.historyitems[0].ID;
                    this.WireTransferRequestID=this.historyitems[0].MediaWireTransferRequestID;

                });
        },
        async ViewPaymentApply(rowData) {
            if(rowData) {
                this.ApplyPaymentID = rowData.ID;
                this.showPaymentApplyModel = true;
            } else {
                this.showPaymentApplyModel = false;
            }
        },
        async GetSavedTemplate(row) {
            const id=row.ID;
       this.$store.dispatch("wiretransfer/GetTemplate", id)
       .then(() => {
         this.showTemplateDataModel = false;
         this.$router.push({
                name: 'Wire Transfer',
                params: {
                    id: parseInt(id),
                    isTemplate: true
                }
            })
      });
     },
        GetAllTemplatesData()
         {
            this.templateData = 0;
            this.totalTemplateRows = [];
                this.$store
                .dispatch("wiretransfer/ListAllTemplates",this.GetTemplateGridOptions())
                .then(response => {
                    if(response.data) {
                        this.templateData = response.data.Result;
                        this.TemplateRecordsPerPageOption = UpdateRecordsPerPageOption(this.totalTemplateRows);
                        this.showTemplateDataModel = true;
                        this.subMenuOpen=false;
                    }
                });
        },
        closeWireHistory() {
            this.showModal = false;
        },
        closePaymentApplyModel() {
            this.showPaymentApplyModel = false;
        },
        closeTemplateData() {
            this.showTemplateDataModel = false;
        },

        closeCommentBox() {
            this.showCommentModel = false;
        },
        submitComment() {
            const comment = document.getElementById("comment-box").value;
            console.log(comment);
            this.showCommentModel = false;
        },
        UserAgency(agency) {
            return this.AllAgencyAccess || (this.IsAgencyArray ? this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e.findIndex(d => d == agency) > -1 : false);
        },
        async LoadAgency() {
            service
                .getAllAgencyDropdownValues("")
                .then((res) => {
                    this.CompanyNameOptions = res.data.sort((a, b) => {
                        let fa = a.label.toLowerCase(),
                            fb = b.label.toLowerCase();
                        if (fa < fb) {
                            return -1;
                        }
                        if (fa > fb) {
                            return 1;
                        }
                        return 0;
                    });
                })
                .catch((err) => {
                    alert(err);
                });
        },
        formatRequestor(email) {
          return formatRequestor(email)
        },
        IsWatcherOnly(row) {
            return !this.IsRequestor(row) && !this.HasCFOApproverPermission; //&& (row.WireTransferRequestWatchers.indexOf(this.loggedInUser) > -1;
        },
        IsRequestor(row) {
            return (row.Requestor != null && row.Requestor.toLowerCase() == this.loggedInUser.toString().toLowerCase());
        },
        IsAuthorizedBy(row) {
            return (row.AuthorizedBy != null && row.AuthorizedBy.toLowerCase() == this.loggedInUser.toString().toLowerCase());
        },
        IsCFOApprover() {
            return this.HasCFOApproverPermission == true;
        },
        IsCFOApproverForInternational(row) {
            return row.TransferType == "International" && this.loggedInUser.toString().toLowerCase() == row.CFOApprover.toString().toLowerCase();
        },
        async downloadItem(file) {
            this.$store
                .dispatch("client/DownloadFile", file)
                .then((response) => {
                    const blob = new Blob([response.data], {
                        type: file.Extension
                    });
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = file.Name;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error while downloading file. ${ex}`
                        );
                    }
                });
        },
        async GetUploadedFile(id) {
            window.console.log(`Uploaded file id ${id}`);
            let index = this.RequestFiles.findIndex((val) => {
                return val == id;
            });
            if (index < 0) {
                this.$store
                    .dispatch("wiretransfer/RenderUploadedFiles", parseInt(id))
                    .then((response) => {
                        this.RequestFiles.push(response.data.ID);
                        let index = this.data.rows.findIndex((val) => {
                            return val.ID == id;
                        })
                        if (index > -1) {
                            this.$set(this.data.rows[index], "Files", response.data.Files);
                        }
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Error while loading file. ${ex.response.data}`
                            );
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Error while loading file. ${ex}`
                            );
                        }
                    });
            }
        },
        ShowEdit(row) {
            return this.IsRequestor(row) && row.Status != 'Cancel' && row.Status != 'Canceled' && (row.Status == "Requested" || row.Status == "Denied" || row.Status == "Returned");
        },
        EditRequest(row) {
            this.$router.push({
                name: 'Wire Transfer',
                params: {
                    id: parseInt(row.ID)
                }
            })
        },
        EditDraftRequest(row) {
            this.$router.push({
                name: 'Wire Transfer',
                params: {
                    id: parseInt(row.ID),
                    isDraft: true
                }
            })
        },
        DeleteRequest(row) {
            this.ShowCancel = true;
            this.CancelIndex = row.index;
        },
        Cancel() {
            this.ShowCancel = false;
            this.CancelIndex = -1;
        },
        formatCurrency(row) {
            let value = row.TotalInvoiceAmount;
            if (typeof value !== "number" && isNaN(value)) {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return formatter.format(value);
        },
        formatPaymentCurrency(row) {
            let val = row.PaymentAmount;
            if (typeof value !== "number" && isNaN(val)) {
                return val;
            }
            var valFormatter = new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return valFormatter.format(val);
        },
        CreateRequest() {
            this.$router.push('/Request/WireTransfer');
        },
        async GetBeneficiary() {
            this.$store
                .dispatch("wiretransfer/GetBeneficiary")
                .then((res) => {
                    this.BeneficiaryOptions = [];
                    if (res.data && res.data.length > 0) {
                        this.BeneficiaryOptions = res.data;
                        this.RecordsPerPageOption = UpdateRecordsPerPageOption(this.BeneficiaryOptions.length);
                    }
                })
                .catch((ex) => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while loading beneficiary. ${ex}`
                    );
                });
        },
        IsValidDate(completionDate) {
            return (
                completionDate != null &&
                moment(completionDate.startDate).isValid() &&
                moment(completionDate.endDate).isValid()
            );
        },
        formatRequested(row) {
            if (row.RequestType == "Client" || row.RequestType == "Intercompany") {
                if (row.CompletedValue) {
                    return `Requested Code: ${row.RequestedValue} | Completed Code: ${row.CompletedValue}`;
                } else if (row.RequestedValue) {
                    return `Requested Code: ${row.RequestedValue}`;
                } else {
                    return "";
                }
            } else if (row.RequestType == "Product") {
                return "";
            } else {
                return "";
            }
        },
        formatStatus(status) {
            if (this.InsideIndex == 3) {
                return "Draft";
            } else if (status != null && status != '') {

                if (status == "Final" || status == "Done" || status == "Complete") {
                    return "Completed";
                } else if (status == "Canceled" || status == "Cancelled") {
                    return "Rejected";
                } else {
                    if (ClientWorkflowStates.PendingCompletionStates.concat(ClientWorkflowStates.PendingApprovalStates).concat(ClientWorkflowStates.PendingRequestStates).concat(ProductWorkflowStates.PendingRequestStates).concat(IntercompanyWorkflowStates.PendingApprovalStates).concat(IntercompanyWorkflowStates.PendingRequestStates).concat(IntercompanyWorkflowStates.PendingCompletionStates).includes(status)) {
                        return `Pending ${status}`;
                    } else {
                        return status;
                    }
                }
            } else {
                return '-';
            }
        },
        GetGridOptions() {
            let extrafilters = [];

            if (
                this.FilterList.PaymentType != null
            ) {
                extrafilters.push({
                    Field: "PaymentType",
                    Operator: "=",
                    Value: this.FilterList.PaymentType,
                });
            }

            if (
                this.FilterList.Beneficiary != null
            ) {
                extrafilters.push({
                    Field: "Beneficiary",
                    Operator: "=",
                    Value: this.FilterList.Beneficiary,
                });
            }

            if (
                this.FilterList.CompanyName != null
            ) {
                extrafilters.push({
                    Field: "Company",
                    Operator: "=",
                    Value: this.FilterList.CompanyName,
                });
            }

            if (
                this.FilterList.Status != null
            ) {
                extrafilters.push({
                    Field: "Status",
                    Operator: "=",
                    Value: this.FilterList.Status,
                });
            }
            return {
                PageSize: parseInt(this.perPage),
                CurrentPageNumber: parseInt(this.currentPage),
                FilterValue: this.filter,
                SortBy: this.sortBy,
                SortDesc: this.sortDesc,
                Filters: extrafilters,
                Status: parseInt(
                    this.InsideIndex
                ),
                IsDraftType: this.OutsideIndex == 1,
                fileType: this.fileType
            };
        },
        buildColumns() {
            let columns = [];
            if (this.OutsideIndex == 0) {
                if (this.InsideIndex != 3) {
                    columns.push({
                        key: "Actions",
                        label: "Actions",
                    });
                }
                columns.push({
                    key: "ID",
                    label: "ID#",
                    sortable: true,
                    class: "NameHoverclass",

                })
                columns.push({
                    key: "PaymentType",
                    label: "Payment Type",
                    sortable: true,
                })
                columns.push({
                    key: "CompanyName",
                    label: "Company",
                    sortable: false,
                })
                columns.push({
                    key: "VendorCode",
                    label: "Vendor Code",
                    sortable: true
                })
                columns.push({
                    key: "Beneficiary",
                    label: "Beneficiary",
                    sortable: true,
                })
                // {
                //   key: "RequestedValue",
                //   label: "Requested Code",
                //   sortable: true,
                // },

                columns.push({
                    key: "Status",
                    label: "Status",
                    sortable: true,
                })
                columns.push({
                    key: "Currency",
                    label: "Currency",
                    sortable: true
                })
                columns.push({
                    key: "TotalInvoiceAmount",
                    label: "Amount",
                    sortable: true,
                })
                columns.push({
                    key: "IsPaymentApplied",
                    label: "Payment Applied",
                    sortable: true,
                    align: 'right'
                });
                columns.push({
                    key: "PaymentAmount",
                    label: "USD Amount",
                    sortable: true,
                    align: 'right'
                });
                columns.push({
                    key: "Requestor",
                    label: "Requestor",
                    sortable: true,
                });
                columns.push({
                    key: "CreatedDate",
                    label: "Date Requested",
                    sortable: true,
                    align: 'center'
                });
                columns.push({
                    key: "WireNo",
                    label: "Wire#",
                    sortable: true,
                    align: 'right'
                });
                if (this.allRequest && this.InsideIndex != 3) {
                    // columns.push({
                    //   key: "CompletedValue",
                    //   label: "Completed Code",
                    //   sortable: true,
                    // });
                    columns.push({
                        key: "CompletionDate",
                        label: "Date Completed",
                        sortable: true,
                        align: 'center'
                    });
                }
            } else {
                columns.push({
                    key: "Actions",
                    label: "Actions",
                });
                columns.push({
                    key: "PaymentType",
                    label: "Payment Type",
                    sortable: true,
                })
                columns.push({
                    key: "CompanyName",
                    label: "Company",
                    sortable: false,
                })
                columns.push({
                    key: "VendorCode",
                    label: "Vendor Code",
                    sortable: true
                })
                columns.push({
                    key: "Beneficiary",
                    label: "Beneficiary",
                    sortable: true,
                })
                columns.push({
                    key: "Currency",
                    label: "Currency",
                    sortable: true
                })
                columns.push({
                    key: "TotalInvoiceAmount",
                    label: "Amount",
                    sortable: true,
                })
                columns.push({
                    key: "Requestor",
                    label: "Requestor",
                    sortable: true,
                });
                columns.push({
                    key: "CreatedDate",
                    label: "Date Requested",
                    sortable: true,
                    align: 'center'
                });
            }
            return columns;
        },
        downloadList(fileType) {
            this.fileType = fileType;
            let fileExtension = fileType === "Excel"? "csv" : "pdf"
            this.$store
                .dispatch("wiretransfer/DownloadList", this.GetGridOptions())
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Requests_${moment().format("MMDDYYYYmmhhss")}.` + fileExtension;
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error downloading requests.`
                    );
                });
        },
        downloadECashAsExcel() {
            this.$store
                .dispatch("wiretransfer/DownloadECashList", this.GetGridOptions())
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Requests_${moment().format("MMDDYYYYmmhhss")}.xlsx`;
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error downloading requests.`
                    );
                });
        },
        closeAudit() {
            this.closeAuditModal = true;
        },
        showAuditTrail(id, requestType) {
            if (this.InsideIndex == 3) {
                this.$store.dispatch(
                    "progress/setErrorMessage",
                    `Can't show draft request audit trail.`
                );
            } else {
                this.requestId = id;
                this.requestType = requestType;
                this.closeAuditModal = false;
            }
        },
        workflowAccessIssueAlert() {
            this.$store.dispatch(
                "progress/setErrorMessage",
                `Don't have permission to approve the request`
            );
        },
        redirectToReview(id) {
            this.$router.push({
                name: `WireTransferReview`,
                params: {
                    id: parseInt(id),
                },
            });
        },
        navigateToEdit(id, status, row) {
            if (status == "Approved" || status == "Canceled") {
                if (this.IsRequestor(row) || (this.canApproveLevel1WireTransferRequest && this.UserAgency(row.VendorName)) || (this.IsCFOApprover(row) || this.IsCFOApproverForInternational(row))) {
                    this.redirectToReview(id);
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to view the request`
                    );
                }
            } else if (status == "Requested" || status == "CFO Approved") {
                if (this.canApproveLevel1WireTransferRequest && this.UserAgency(row.VendorName)) {
                    this.$router.push({
                        path: `/WireTransferApproveLevel1/${id}`
                    });
                } else {
                    if (this.IsRequestor(row)) {
                        this.redirectToReview(id);
                    }else{
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Don't have permission to approve the request`
                        );
                    }
                }
            } else if (status == "In Review") {
                if (this.canApproveLevel1WireTransferRequest && this.UserAgency(row.VendorName)) {
                    this.$router.push({
                        path: `/WireTransferApproveLevel1/${id}`
                    });
                } else {
                    if (this.IsRequestor(row)) {
                        this.redirectToReview(id);
                    }else{
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Don't have permission to approve the request`
                        );
                    }
                }
            } else if (status == "CFO Approval Requested") {
                if (this.IsCFOApprover(row) || this.IsCFOApproverForInternational(row)) {
                    this.$router.push({
                        path: `/WireTransferApproveLevel2/${id}`
                    });
                } else {
                    if (this.IsRequestor(row)) {
                        this.redirectToReview(id);
                    }else{
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Don't have permission to approve the request`
                        );
                    }
                }
            }
            else if (status == "Denied") {
                if (this.IsRequestor(row)) {
                    this.$router.push({
                        name: "Wire Transfer",
                        params: {
                            id: row.ID
                        }
                    });
                }
                else
                {
                    this.$store.dispatch(
                    "progress/setErrorMessage",
                   `You don't have permission to edit this request as you are not the requester`
                    );
                }
            }
            else if (status == "Returned") {
                if (this.IsRequestor(row)) {
                    this.$router.push({
                        name: "Wire Transfer",
                        params: {
                            id: row.ID
                        }
                    });
                }
                else
                {
                    this.$store.dispatch(
                    "progress/setErrorMessage",
                   `You don't have permission to edit this request as you are not the requester`
                    );
                }
            }
        },
        GetData(row) {
            return {
                ID: parseInt(row.ID),
                Comments: this.CancelNotes,
                // ActiveTaskID: this.Request.ActiveTaskID | 0,
                ProjectID: row.OmniflowProjectID | 0,
                ProcessID: row.OmniflowProcessID | 0,
            };
        },
        GetGridRowByIndex() {
            if (this.CancelIndex > -1) {
                return this.data.rows[this.CancelIndex + (this.currentPage - 1) * this.perPage]
            }
        },
        CancelRequest(row) {
            window.console.log(JSON.stringify(row));
            this.$store
                .dispatch("wiretransfer/CancelRequest", this.GetData(this.GetGridRowByIndex()))
                .then(() => {
                    this.ShowCancel = false;
                    this.$store.dispatch(
                        "progress/setSuccessMessage",
                        `Wire Transfer Request has been canceled.`
                    );
                    this.GetRequests();
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error fetching requests.${ex}`
                        );
                    }
                });
        },
        RestValues: function () {
            this.FilterList.PaymentType = null;
            this.FilterList.CompanyName = null;
            this.FilterList.Beneficiary = null;
            this.FilterList.Status = null;
            this.currentPage = 1;
            this.GetRequests();
        },
        createNew(name) {
            this.$router.push(name);
        },
        getWindowWidth: function () {
            this.getFixed = document.documentElement.clientWidth > 992 ? true : null;
        },
        getBackgroundColor: function (status) {
            let color = "#8c8c8c";
            let emptyStatus = '';
            let emptyStatusNull = null;
            switch (status) {
                case ClientWorkflowStates.ApproveLevel1:
                    color = "#efa56a";
                    break;
                case IntercompanyWorkflowStates.ApproveLevel1:
                    color = "#efa56a";
                    break;
                case ClientWorkflowStates.ApproveLevel2:
                    color = "#efa56a";
                    break;
                case IntercompanyWorkflowStates.ApproveLevel2:
                    color = "#efa56a";
                    break;
                case ClientWorkflowStates.ApproveLevel3:
                    color = "#efa56a";
                    break;
                case ClientWorkflowStates.Final:
                    color = "#2cc4ad";
                    break;
                case ClientWorkflowStates.Canceled:
                    color = "#df5678";
                    break;
                case IntercompanyWorkflowStates.Canceled:
                    color = "#df5678";
                    break;
                case ClientWorkflowStates.OpenCodeInAxandDDs:
                    color = "#2c3865";
                    break;
                case ClientWorkflowStates.AxUpdateTheRequestWithSystemCode:
                    color = "#a2a9ad";
                    break;
                case IntercompanyWorkflowStates.AxUpdateTheRequestWithSystemCode:
                    color = "#efa56a";
                    break;
                case ProductWorkflowStates.Final:
                    color = "#2cc4ad";
                    break;
                case emptyStatus:
                    color = "";
                    break;
                case emptyStatusNull:
                    color = "";
                    break;
                default:
                    color = "#ccc";
                    break;
            }

            return {
                color: color,
                "font-weight": 600
            };
        },
    },
};
</script>
