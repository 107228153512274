const  ApproveLevel1 = "Finance Approval", ApproveLevel2 = "Complete Request",Final = "Completed", Canceled = "Rejected";

const AllStates=[
    ApproveLevel1,
    ApproveLevel2,
    Final,
    Canceled,
]

const PendingRequestStates=[
    ApproveLevel1,
]

const PendingCompletionStates=[
    ApproveLevel2,
]
export { Final,Canceled,ApproveLevel1,ApproveLevel2, AllStates,PendingCompletionStates,PendingRequestStates };