<template>
<div style="padding-top: 35px">
    <div class="d-block row">
        <div class="col-lg-12">
            <div class="row" style="margin-top: -48px !important; margin-bottom: 10px !important">
                <!-- <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="InsideIndex" @input="tabChange()">
                    <b-tab title="All"> </b-tab>
                    <b-tab title="Pending"> </b-tab>
                    <b-tab title="Completed"> </b-tab>
                </b-tabs> -->
                <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="OutsideIndex" @input="tabChange()">
                    <b-tab title="Requests"> </b-tab>
                    <!-- <b-tab title="Drafts"> </b-tab> -->
                </b-tabs>
            </div>
            <div class="row" :style="{
            'margin-bottom': index != 0 ? '10px' : 'auto',
          }">
                <button style="margin-left: 9px !important" class="btn btn-link text-uppercase Open-Filter" @click="openfilter()" v-show="!ShowFilter">
                    <b-img width="11" height="9" :src="openFilterIcon" /> Open Filter
                </button>
                <div class="offset-lg-3" v-show="ShowFilter">
                    <button style="margin-left: -12px !important" class="btn btn-link text-uppercase Close-Filter" @click="closefilter()">
                        <b-img width="11" height="9" :src="closeFilterIcon" /> Close
                        Filter
                    </button>
                </div>
            </div>

            <div class="row mb-3">
                <!-- <div v-bind:class="[
              {
                'col-md-6 ': !ShowFilter,
                'col-md-5 offset-md-3': ShowFilter,
              },
            ]">
                    <div class="row">
                        <div class="col-lg-6">
                            <input type="text" class="form-control" v-model="filter" placeholder="Search" />
                        </div>
                    </div>
                </div> -->
                <div v-bind:class="[
              {
                'col-md-6 ': !ShowFilter,
                'col-md-4 offset-md-3': ShowFilter,
              },
            ]">
                    <div class="row" v-if="OutsideIndex==0">
                        <div class="col-lg-4" :class="{ 'col-lg-5': ShowFilter }">
                            <OmniSelect v-model="InsideIndex" :options="FilterTabOptions" name="" :showLabel="false" placeholder="" :showSearch="false" />

                        </div>
                        <div class="col-lg-6">
                            <input type="text" class="form-control" v-model="filter" placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div v-bind:class="[
              {
                'col-md-6 text-right ': !ShowFilter,
                'col-md-5 text-right': ShowFilter,
              },
            ]">
                    <b-button class="btn custom-button" text="Download As Excel" @click="DownloadList()" variant="outline-primary">
                        Download As Excel
                    </b-button>
                    <button v-if="canApproveProfileRequestLevel4" class="btn custom-button btn-primary" :disabled="IsRelockSelected" text="Relock Request" @click="BulkRelockRequest()" variant="outline-primary">
                        Relock Request
                    </button>
                    <button class="btn custom-button btn-primary" v-if="this.CanCreateRequest"   @click="CreateRequest()" id="create-profile-request" text="Create New">
                        CREATE REQUEST
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3" v-if="ShowFilter">
                    <div>
                        <div class="form-group col-md-12">
                            <OmniSelect class="omniipclass" :options="StatusOptions" v-model="FilterList.Status" name="STATUS" placeholder="Filter by Status" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" multiple="true"/>
                        </div>
                        <div class="form-group col-md-12">
                            <OmniSelect class="omniipclass" :options="AgencyList" v-model="FilterList.Agency" name="Agency" placeholder="Filter by Agency" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" multiple="true"/>
                        </div>
                        <div class="form-group col-md-12">
                            <OmniSelect class="omniipclass" :options="ToolkitOptions" v-model="FilterList.Toolkit" name="Toolkit" placeholder="Filter by Toolkit" :showSearch="false" :showSelectAll="true" selectAllText="All" unSelectAllText="None" multiple="true"/>
                        </div>
                        <div class="row text-right">
                            <div class="col-md-11 p-0">
                                <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="ResetValues()">
                                    RESET
                                </button>
                                <button class="btn btn-primary text-uppercase" :disabled="!IsFormFilterValid" v-on:click="FilterQuery()">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-bind:class="[
              { 'col-md-12 ': !ShowFilter, 'col-md-9': ShowFilter },
            ]">
                    <b-table id="all-items" :per-page="perPage" :items="data.rows" :fields="fields" no-local-sorting=true @sort-changed="sortChange" show-empty striped hover responsive class="all-items">

                        <template v-slot:cell(CreatedDate)="data">
                            <div class="text-center">
                                {{ data.value | formatDate }}
                            </div>
                        </template>
                        <template v-slot:cell(Actions)="data">

                            <div class="action-cell" style="display:inline">
                                <b-img width="12" height="12" :src="auditIcon" @click="showAuditTrail(data.item.ID, data.item.RequestType)" class="audit-trail" title="Audit Trail" v-b-tooltip.hover.top />
                            </div>
                            <div class="action-cell" style="display:inline;padding-left:5px">
                                <b-img width="12" height="12" v-if="ShowDelete(data.item)" :src="deleteIcon" @click="deleteRequest(data.item.ID, data.item.RequestType,data.item.Agency)" class="audit-trail" title="Delete" v-b-tooltip.hover.top />
                            </div>
                            <div class="action-cell" style="display:inline">
                                    <b-img width="20" height="20" v-if="ShowReplicateRequest(data.item)" :src="reassignIcon"
                                        @click="ReplicateRequest(data.item)" class="audit-trail"
                                        title="Replicate Request" v-b-tooltip.hover.top />
                                </div>
                            <span class="checkbox-new" v-if="canApproveProfileRequestLevel4 && data.item.Status == 'Relock Request'">
                                <b-form-checkbox @change="BulkRelockData" :disabled="DisableBulkRelock(data.item.ID)" v-model="BulkRelockID" :value="data.item.ID" inline></b-form-checkbox>
                            </span>

                        </template>
                        <template v-slot:cell(ClientCode)="data">

                            <a class="edit-link" @click="
                    navigateToEdit(
                      data.item.ID,
                      data.item.RequestType,
                      data.item.Status,
                      data.item.Requestor
                    )
                  ">{{formatRequested(data.item)}}</a>
                        </template>

                        <template v-slot:table-colgroup="scope" v-if="allRequest">
                            <col v-for="field in scope.fields" :key="field.key" :style="{
                    width: field.label==='Request'?'300px':field.key === 'audit' ? '50px':'auto',
                  }" />
                        </template>
                        <template v-slot:cell(RequestType)="data">
                            <div class="text-center">
                                {{ data.value  }}
                            </div>
                        </template>
                        <template v-slot:cell(CompletiondDate)="data">
                            <div class="text-center">
                                {{ data.value | formatDate }}
                            </div>
                        </template>

                        <template v-slot:cell(Requestor)="data">
                            <div>
                                {{ formatRequestor(data.value) }}
                            </div>
                        </template>
                        <template v-slot:cell(Toolkit)="data">
                            <div class="text-center">
                                {{ formatToolkit(data.value) }}
                            </div>
                        </template>
                        <template v-slot:cell(Status)="data">
                            <div style="white-space: nowrap" class="text-left">
                                <span :style="getBackgroundColor(data.value)">
                                    {{ formatStatus(data.value) }}
                                </span>
                            </div>
                        </template>
                        <template slot="custom-foot" v-if="IsPaginationRequired">
                            <b-tr>
                                <b-td class="custom-pagination" colspan="13">
                                    <b-form-select class="records-per-page" v-model="perPage" :options="RecordsPerPageOption"></b-form-select>
                                    <Pagination style="float: right" :per-page="perPage" aria-controls="open-items" v-model="currentPage" :total-rows="TotalRows" first-number last-number />
                                </b-td>
                            </b-tr>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
    <component :is="currentAuditTrail" v-bind="{ id: requestId, closeAuditModal: closeAuditModal }" @closeAudit="closeAudit()"></component>
    <b-modal modal-class="cancel-request" dialog-class="cancel-request" v-model="ShowCancel" :no-close-on-backdrop="true" size="sm">
        <template v-slot:modal-header="{ close }">
            <h5 class="modal-title">Cancel wire transfer request</h5>
            <button class="close" @click="Cancel()"></button>
        </template>
        <div>
            <p class="cancel-info-text">
                Your wire transfer request will be canceled and this action is permanent. Please provide a brief reason for the cancelation.
            </p>
            <div>
                <b-textarea :placeholder="'Add details... Minimum 50 characters'" :rows="5" v-model="CancelNotes" class="">

                </b-textarea>
            </div>

        </div>
        <template v-slot:modal-footer="{ ok, cancel }">
            <div class="text-right footer-buttons" v-if="ShowCancel==true">
                <button class="btn custom-button btn-secondary" @click="Cancel()">
                    Never Mind
                </button>
                <button :disabled="ShowCancel==true&&$v.$invalid" class="btn custom-button btn-primary" @click="CancelRequest()">
                    Cancel Request
                </button>

            </div>
        </template>
    </b-modal>
    <b-modal no-enforce-focus no-close-on-backdrop size="xl" v-model="showReplicateModal" class="Title-Text" hide-footer
             title="Replicate profile unlock request" style="z-index:1;" @close="onClose">
        <p class="mx-3">
            Do you want to replicate the profile unlock request?
        </p>
        <p class="mx-3">
            <b>Note:</b> The Invoice copy and Screenshot of error attachments will not be copied to the new request, and the time period of the buy will be set to the current month and year.
        </p>
        <br />
        <div class="text-right">
            <button class="btn custom-button btn-secondary" @click="CancelReplicateRequest()">
                No
            </button>
            <button class="btn custom-button btn-primary" @click="ReplicateRequestConfirm()">
                Yes
            </button>
        </div>
    </b-modal>
</div>
</template>

<style>
.checkbox-new .custom-control{
    position: absolute;
    left: 60px;
    right: auto;
    margin: auto;
    width: 20px;
}
.cancel-info-text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #47505a;
}

.Custom-Edit {
    padding-left: 10px;
}

.Custom-Delete {
    padding-left: 10px;
}

.row-details-container {
    background-color: #fff;
    border: 1px solid #ececec;
    margin-left: 40px;
    margin-top: 10px;
    min-height: 60px;
    margin-right: 40px;
    margin-bottom: 10px;

}

.row-detail-title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
    padding: 10px 0px 0px 10px;
}

.row-details-container>.support-document:after {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    position: absolute;
    right: 0%;
    top: 10%;
    height: 80%;
    border-right: 1px solid #b7bcd6;
}

.row-details-notes {
    padding-left: 10px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.list-tab {
    font-size: 14px;
    color: #00a1d2 !important;
}

.tooltip-inner {
    background-color: #f7f7f9 !important;
    color: #ffffff !important;
}

.tooltip-inner::before {
    border-top-color: #f7f7f9 !important;
    border-bottom-color: #f7f7f9 !important;
    border-left-color: #f7f7f9 !important;
    border-right-color: #f7f7f9 !important;
}

a.edit-link {
    color: #00a1d2 !important;
}

table.b-table tbody tr td {
    line-height: 1.2 !important;
}

.popover-body>div>div:first-child>div {
    margin-top: 15px !important;
}

.popover-body>div>div:not(:first-child):not(:last-child)>div {
    margin-top: 20px !important;
}

.popover-body>div>div:last-child>div {
    margin-top: 20px !important;
    margin-bottom: 15px !important;
}

.popover-body>div div.left {
    height: 12px !important;
}

.b-table tbody>tr {
    height: 40px !important;
}

.table td {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

table tfoot tr td {
    padding-right: 0px !important;
}

.form-control[placeholder="Search"] {
    background: center right 7px no-repeat url(../assets/Icons/search.svg) #fff !important;
}

e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #00a1d2 !important;
    border-color: transparent !important;
    color: #fff !important;
}

.e-multi-select-list-wrapper .e-selectall-parent {
    margin-left: 40px !important;
}

.back-arrow:after {
    content: " \00AB";
    width: 4px;
    height: 7px;
}

.btn-link {
    color: #00a1d2 !important;
}

.All-Requests {
    width: 98px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.table.b-table>thead>tr>[aria-sort="none"],
.table.b-table>tfoot>tr>[aria-sort="none"],
.table.b-table>thead>tr>[aria-sort="ascending"],
.table.b-table>tfoot>tr>[aria-sort="ascending"],
.table.b-table>thead>tr>[aria-sort="descending"],
.table.b-table>tfoot>tr>[aria-sort="descending"] {
    background-position-y: 25px !important;
    background-position-x: 95% !important;
}

.container2:hover p span {
    filter: invert(45%) sepia(81%) saturate(3821%) hue-rotate(167deg) brightness(102%) contrast(101%);
}

.container2:hover p {
    fill: #00a1d2 !important;
    color: #00a1d2 !important;
}

.iconDetails {
    margin-left: -28%;
    float: left;
    height: 14px;
    width: 40px;
}

.container2 {
    width: 270px;
    min-height: 25px;
    float: left;
}

.left {
    float: left;
    width: 45px;
}

.right {
    margin-left: -23px;
    float: left;
    width: 149px;
}

.Apply {
    margin-left: 17px;
    height: 19px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.44px;
    color: #88d5ec;
    border-radius: 25px !important;
}

.Apply:hover {
    text-decoration: none !important;
    color: white !important;
    background-color: #018db8 !important;
    border-radius: 25px !important;
}

.Filters {
    width: 49px;
    height: 24px;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.Open-Filter {
    /* width: 74px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.All-Requests {
    width: 123px;
    height: 22px;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a1d2;
}

.Open-Filter {
    padding-top: 16px;
    /* width: 123px; */
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Close-Filter {
    margin-left: 15px;
    padding-top: 20px;
    width: 150px;
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.Container {
    width: 1268px;
    height: 353px;
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffffff;
}

.b-popover p {
    cursor: pointer !important;
}

.tooltip-inner {
    background-color: #2c3865 !important;
}

#body-container>.container {
    padding-top: 30px !important;
}

.btn-info:hover {
    color: #fff;
    background-color: #1388b7 !important;
}

table>tr>td {
    height: 40px !important;
}

.icon-add-client {
    background-image: url("../assets/Icons/add-client.svg");
}

.icon-add-product {
    background-image: url("../assets/Icons/add-product.svg");
}

.icon-add-intercompany {
    background-image: url("../assets/Icons/add-intercompany.svg");
}

.icon-add-vendor {
    background-image: url("../assets/Icons/add-vendor.svg");
}

.icon-custom {
    height: 19px;
    width: 19px;
    background-repeat: no-repeat;
    display: inline-block;
}

.card {
    border: 0px !important;
}

.filter-icon {
    background-image: url("../assets/Icons/f.png");
    background-repeat: no-repeat;
    vertical-align: super;
}

.display-hover {
    height: 20px;
    width: 20px;
    text-align: center;
}

tbody tr:not(:hover) .display-hover>img.action-icons-hover,
tbody tr:not(:hover) .display-hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:not(:hover)>img.action-icons-hover,
tbody tr:hover .display-hover:hover>img.action-icons {
    display: none;
}

tbody tr:hover .display-hover:hover>img.action-icons-hover,
tbody tr:hover .display-hover:not(:hover)>img.action-icons {
    display: inline-block;
}

tbody tr>td {
    height: 40px !important;
    cursor: default !important;
}

tbody tr>td .audit-trail {
    cursor: pointer !important;
}

td.NameHoverclass:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

td.NameHoverclass {
    overflow-wrap: anywhere !important;
    /* text-decoration: underline !important; */
}

#cardheader .card-header {
    background-color: rgba(127, 137, 152, 1) !important;
}

tbody {
    background-color: white !important;
}

.dataTables_length {
    display: none !important;
}

thead {
    background-color: rgba(123, 129, 136, 1) !important;
}

/* .nav-tabs {
  border-bottom: 1px solid white !important;
} */

.tabs {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.tab-content {
    background-color: #ffffff !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
    padding: 20px !important;
}

/* .input-group {
    display: none !important;
} */

.vbt-per-page-dropdown {
    display: none !important;
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: calc(100% - 31%) !important;
    }
}

.close {
    outline: none !important;
}

.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group:not(.e-float-icon-left) {
    border: 1px solid #ced4da !important;
    width: 290px !important;
}

.popoverhover {
    font-family: "Open Sans";
    font-size: 12px;
}

.popoverhoverproduct {
    font-family: "Open Sans";
    font-size: 12px;
}

.clientnamehover :hover {
    text-decoration: underline !important;
}

.omniipclass {
    font-family: "Open Sans" !important;
}

.Open-Filter img,
.Close-Filter img {
    vertical-align: initial !important;
}
</style>

<script>
const moment = require("moment");
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import * as ProfileUnlockStates from "../utilities/ProfileUnlockStatus";
import {formatRequestor} from "../utilities/Helper";
import {ADCountries} from "../utilities/CommonConst";
import {mapGetters} from "vuex";
import {requiredIf,minLength} from "vuelidate/lib/validators";
import {UpdateRecordsPerPageOption} from "./../utilities/paginationUtils";
import service from "../services/Dropdowns";
import store from "@/store";
export default {
    components: {
        DateRangePicker,
    },

    data() {
        return {
            BulkRelockID: [],
            BulkRelockProjectID:[],
            BulkRelockProcessID:[],
            IsRelockSelected: true,
            AgencyName: null,
            AgencyList: [],
            RecordsPerPageOption: [],
            CanCreateRequest: false,
            CancelNotes: null,
            ShowCancel: false,
            deleteIcon: require("@/assets/Icons/delete.svg"),
            collapseIcon: require("@/assets/Icons/arrow-down.svg"),
            expandIcon: require("@/assets/Icons/rightarrow.svg"),
            editIcon: require("@/assets/Icons/edit.svg"),
            FilterTabOptions: [{
                    label: 'All Requests',
                    value: "0"
                },
                {
                    label: 'Completed',
                    value: "1"
                },
                {
                    label: 'Pending Approval',
                    value: "2"
                },
                {
                    label: 'Pending Relock',
                    value: "3"
                }
            ],
            StatusOptions: [{
                    label: "BPM Approval",
                    value: "BPM Approval"
                },
                {
                    label: "MR Approval",
                    value: "MR Approval"
                },
                {
                    label: "Unlock Request",
                    value: "Unlock Request"
                },
                {
                    label: "ReLock Request",
                    value: "ReLock Request"
                },
                {
                    label: "Completed",
                    value: "Complete"
                },
                {
                    label: "Rejected",
                    value: "Reject"
                }
            ],
            ToolkitOptions: [{
                    label: "Net",
                    value: "Net"
                },
                {
                    label: "Print/Prisma",
                    value: "PrintPrisma"
                }],
            MasterClientCodes: [],
            locale: {
                applyLabel: "Set",
                cancelLabel: "Cancel",
            },
            button: "button title",
            InsideIndex: "0",
            OutsideIndex: 0,
            TotalRows: 0,
            requestType: null,
            requestId: 0,
            closeAuditModal: true,
            requestor: null,
            showReplicateModal: false,
            addClient: require("@/assets/Icons/add-client.svg"),
            addProduct: require("@/assets/Icons/add-product.svg"),
            addIntercompany: require("@/assets/Icons/add-intercompany.svg"),
            addVendor: require("@/assets/Icons/add-vendor.svg"),
            closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
            openFilterIcon: require("@/assets/Icons/open-filter-icon.svg"),
            auditIcon: require("@/assets/Icons/auditTrail_icon.svg"),
            reassignIcon: require("@/assets/Icons/clone.svg"),

            filter: null,
            sortBy: "CreatedDate",
            sortDesc: true,
            theme: "row",
            ShowFilter: false,
            OmniflowStatus: {
                ApprovLevel1: "Finance Approval",
                ApprovLevel2: "AR Approval",
                ApprovLevel3: "IC Approval",
                ApprovLevel4: "Open codes in AX and DDS",
                ApprovLevel5: "Update the request with System Code",
                Rejected: "Rejected",
                Completed: "Completed",
            },
            FilterList: {
                PaymentType: null,
                CompanyName: null,
                Beneficiary: null,
                Status: [],
                Agency: [],
                Toolkit: [],
            },
            RequestTypes: [{
                    value: "1",
                    label: "Client"
                },
                {
                    value: "2",
                    label: "Product"
                },
                {
                    value: "3",
                    label: "Inter Company"
                },
                {
                    value: "4",
                    label: "Vendor"
                },
            ],
            RequestTypeFields: {
                text: "RequestType",
                value: "Id"
            },

            Status: [],
            RequestStatusFields: {
                text: "RequestSts",
                value: "Id"
            },
            MediaTypeField: {
                text: "MediaTypeVal",
                value: "Id"
            },
            showSelectAll: true,
            getFixed: true,
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            showfilter: false,
            showfilterOpenItems: false,
            perPage: 10,
            currentPage: 1,
            DateFormat: "MM/DD/YYYY",
            elegant: "black",
            fields: this.buildColumns(),
            data: {
                config: {
                    card_mode: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    pagination_info: true,
                    global_search: {
                        visibility: false,
                    },
                },
                loading: false,
                classes: {
                    tableWrapper: "outer-table-div-class wrapper-class-two grid-table",
                    table: {
                        "table-sm": true,
                    },
                },
                rows: [],
            },
        };
    },
    validations: {
        CancelNotes: {
            minLength: minLength(50),
            required: requiredIf(function () {
                return this.ShowCancel == true;
            })
        }

    },
    created() {
        this.$store.commit("client/reset");
        this.$on("closeAudit", function () {
            this.closeAudit();
        });
        this.getAdEmpDetails();
    },
    props: {
        allRequest: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        requestid: {
            type: Number,
            required: false,
        },
    },
    mounted() {
        this.LoadAgency();
        this.$nextTick(function () {
            window.addEventListener("resize", this.getWindowWidth);
            window.console.log(this.$refs.picker);
        });
    },
    watch: {
        InsideIndex: function () {
            this.fields = this.buildColumns();
            this.GetRequests();
        },
        filter(e) {
            if ((e != null && e.length > 2) || e == null || e.length == 0) {
                this.GetRequests();
            }
        },
        currentPage: function (value) {
            if (value != null && parseInt(value) > 0) {
                this.ResetBulkRelock();
            }
        },
        perPage: function () {
            this.currentPage = 1;
            this.GetRequests();
        },
        BulkRelockID: function (NewVal) {
            if(NewVal.length > 0) {
                this.IsRelockSelected = false;
            }
            if(!NewVal || NewVal == false) {
                this.IsRelockSelected = true;
            }
        },
    },
    computed: {
        DisableBulkRelock() {
            return (id) => {
                if(this.BulkRelockID.length >= 10 && !this.BulkRelockID.includes(id)) {
                    return true;
                }
                    return false;
                }
        },
        IsFormFilterValid() {
            return this.FilterList.Status.length > 0 || this.FilterList.Agency.length > 0 || this.FilterList.Toolkit.length > 0;
        },
        ...mapGetters({
            loggedInUser: "emailAddress"
        }),
        canDeleteProfileRequest() {
            return this.$store.getters.canDeleteProfileRequest;
        },
        IsPaginationRequired() {
            return this.TotalRows != null && parseInt(this.TotalRows) > parseInt(this.perPage);
        },

        canApproveProfileRequestLevel2() {
            return this.$store.getters.canApproveProfileRequestLevel2;
        },
        canCreateProfileRequest() {
            return this.canApproveProfileRequestLevel1 != true && this.CanCreateRequest==true;
        },
        canApproveProfileRequestLevel1() {
            return this.$store.getters.canApproveProfileRequestLevel1;
        },
        canApproveProfileRequestLevel3() {
            return this.$store.getters.canApproveProfileRequestLevel3;
        },
        canApproveProfileRequestLevel4() {
            return this.$store.getters.canApproveProfileRequestLevel4;
        },
        canUpdateClientRequestCode() {
            return this.$store.getters.canUpdateClientRequestCode;
        },
        canReadProfileRequest() {
            return this.$store.getters.canReadProfileRequest;
        },
        currentAuditTrail() {
            return this.closeAuditModal != true ?
                this.requestType === "Client" ?
                "client-audit" :
                this.requestType === "Product" ?
                "product-audit" :
                this.requestType === "Intercompany" ?
                "ic-audit" :
                this.requestType === "Profile Unlock" ?
                "profile-audit" :
                "" : "";
        },

        CanAccessList() {
            return this.$store.getters.canAccessList || this.CanCreateRequest;
        },
    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
        },
        formatDateAndTime(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY h:mma") : "-";
        },

    },
    methods: {
        ResetBulkRelock() {
            this.BulkRelockID = [];
            this.BulkRelockProjectID = [];
            this.BulkRelockProcessID = [];
            this.GetRequests();
        },
        BulkRelockData() {
            this.BulkRelockProjectID = [];
            this.BulkRelockProcessID = [];
            this.BulkRelockID.forEach(id => {
                const row = this.data.rows.find(data => data.ID === id);
                if(row) {
                    this.BulkRelockProjectID.push(row.OmniflowProjectID);
                    this.BulkRelockProcessID.push(row.OmniflowProcessID);
                }
            });
        },
        GetData() {
            return this.BulkRelockID.map((id, index) => ({
                Id: id,
                ProjectId: this.BulkRelockProjectID[index],
                ProcessID: this.BulkRelockProcessID[index]
            }));
        },
        BulkRelockRequest() {
            this.$store
                .dispatch("client/BulkRelock", this.GetData())
                    .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `The Profile Unlock has been completed.`
                    );
                    this.ResetBulkRelock();
                    this.$router.push("/ProfileUnlock");
                })
                .catch((ex) => {
                if (ex.response) {
                    this.SetErrorMessage(
                    "Error while approving request",
                    ex.response.data
                    );
                } else {
                    this.SetErrorMessage("Error while approving request", ex);
                }
                });
        },
        canCheckAccess() {
            if (this.CanAccessList) {
                this.GetRequests();
            } else {
                this.$router.push({
                    name: "unauthorized",
                    params: {
                        message: "UnAuthorized"
                    },
                });
            }
        },
        getAdEmpDetails() {
            this.$store
                .dispatch("client/GetADEmpDetails", "")
                .then((res) => {
                    if (res.data.Country != null && ADCountries.some(country => country.toLowerCase() == res.data.Country.toLowerCase())) {
                        this.CanCreateRequest = true;
                    } else {
                        this.CanCreateRequest = false;
                    }
                    this.canCheckAccess();
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    }
                    this.canCheckAccess();
                });
        },
        CreateRequest() {
            this.$router.push('/Request/ProfileUnlock');
        },
        DeleteRequest() {
            this.ShowCancel = true;
        },
        CancelRequest() {
            this.ShowCancel = false;

        },
        Cancel() {
            this.ShowCancel = false;
        },
        deleteRequest(id, requestType, name) {
            var deleteConfirm = confirm(`Are you sure you want to delete the ${requestType} request with name ${name}`);
            if (deleteConfirm == true) {
                this.$store
                    .dispatch("client/DeleteProfileUnlock", parseInt(id))
                    .then(() => {
                        this.$store.dispatch(
                            "progress/setSuccessMessage",
                            "Request has been deleted successfully!"
                        );
                        this.GetRequests();
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `${ex.response.data}`
                            );
                        } else {
                            this.$store.dispatch(
                                "progress/setErrorMessage",
                                `Error while deleting request.${ex}`
                            );
                        }
                    });
            }
        },
        ShowReplicateRequest(row) {
            return row.Status == ProfileUnlockStates.Finished && this.CanCreateRequest && this.isRequestorbyEmail(row.Requestor);
        },

        CancelReplicateRequest() {
            this.showReplicateModal = false;
        },
        ReplicateRequest(item) {
            this.requestId = item.ID;
            this.requestor = item.Requestor;
            this.showReplicateModal = true;
        },
        ReplicateRequestConfirm() {
            if (this.isRequestorbyEmail(this.requestor)) {
                    this.$router.push({
                        name: `Profile Unlock`,
                        params: {
                            requestid: parseInt(this.requestId),
                            isClone: true
                        },
                    })
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to edit the request`
                    );
                }
        },

        formatCurrency(row) {
            let value = row.TotalInvoiceAmount;
            if (typeof value !== "number" && isNaN(value)) {
                return value;
            }
            var formatter = new Intl.NumberFormat('en-US');
            return formatter.format(value);
            //return value;
        },

        IsRequestor(row) {
            return (row.Requestor != null && row.Requestor.toLowerCase() == this.loggedInUser.toString().toLowerCase());
        },

        isRequestorbyEmail(email) {

            return email.toLowerCase() == this.loggedInUser.toString().toLowerCase();
        },

        ShowDelete(row) {
            return row.Status == "Cancel" && (this.IsRequestor(row) || this.canDeleteProfileRequest);
        },

        tabChange() {
            this.fields = this.buildColumns();

            this.applyFilter();
        },
        applyFilter() {
            //this.currentPage = 1;
            if (this.InsideIndex == 1) {
                this.FilterList.Status = [];
                this.FilterList.Status.push(ProfileUnlockStates.ApproveLevel1);
                this.FilterList.Status.push(ProfileUnlockStates.ApproveLevel2);
                this.FilterList.Status.push(ProfileUnlockStates.ApproveLevel3);
                this.FilterList.Status.push(ProfileUnlockStates.ApproveLevel4);
            } else if (this.InsideIndex == 2) {
                this.FilterList.Status = [];
                this.FilterList.Status.push(ProfileUnlockStates.Finished);
            } else {
                this.FilterList.Status = [];
            }
            this.currentPage = 1;
            this.GetRequests();
        },
        formatRequestor(email) {
          return formatRequestor(email)
        },
        formatHFMCode(HFMCode) {
            let masterClientCode = "";
            if (HFMCode) {
                let masterClientCodeExists = (HFMCode.match(/-/g) || []).length;
                if (masterClientCodeExists > 1) {
                    masterClientCode = HFMCode.substring(0, HFMCode.indexOf("-"));
                } else {
                    let customEntity = HFMCode.split('–');
                    if (customEntity.length > 1) {
                        masterClientCode = customEntity[1];
                    }
                }
            }
            return masterClientCode;
        },
        IsValidDate(completionDate) {
            return (
                completionDate != null &&
                moment(completionDate.startDate).isValid() &&
                moment(completionDate.endDate).isValid()
            );
        },
        formatRequested(row) {
            if (row.RequestType == "Profile Unlock") {
                if (row.ClientCode) {
                    return `Client Code: ${row.ClientCode} | Media  : ${row.UnlockMediaType}`;
                } else {
                    return "";
                }
            }
        },
        formatName(row) {
            if (row.RequestType == "CliProfile Unlockent") {
                return `${row.Name} | ${this.formatHFMCode(row.HFMCode)}`;
            } else {
                return "";
            }
        },

        formatBillingParty(row) {
            if (row.BillingParty) {
                return row.BillingParty;
            } else {
                return '-';
            }
        },
        formatStatus(status) {

            if (status != null && status != '') {
                if (status == "Final" || status == "Done" || status == "Complete") {
                    return "Completed";
                } else if (status == "Cancel" || status == "Reject") {
                    return "Rejected";
                } else {
                    return `Pending ${status}`;
                }
            } else {
                return '-';
            }
        },
        formatToolkit(val) {
            if (val != null && val != '') {
                if (val == "PrintPrisma") {
                    return "Print/Prisma";
                }
                else if (val == "Net") {
                    return "Net";
                }
            } else {
                return '-';
            }
        },
        onFiltered(e) {
            if ((e != null && e.length > 2) || e == null || e.length == 0) {
                this.currentPage = 1;
                this.GetRequests();
            }
        },
        sortChange(e) {
            this.sortBy = e.sortBy;
            this.sortDesc = e.sortDesc;
            this.GetRequests();
        },
        GetGridOptions() {

            let extrafilters = [];
            if (this.FilterList.Status != null && this.FilterList.Status != "") {
                extrafilters.push({
                    Field: "Status",
                    Operator: "=",
                    Value: this.FilterList.Status.join(","),
                });
            }
            if (this.FilterList.Agency != null && this.FilterList.Agency.length > 0) {
                extrafilters.push({
                    Field: "Agency",
                    Operator: "=",
                    Value: this.FilterList.Agency.join(","),
                });
            }
            if (this.FilterList.Toolkit != null && this.FilterList.Toolkit.length > 0) {
                extrafilters.push({
                    Field: "Toolkit",
                    Operator: "=",
                    Value: this.FilterList.Toolkit.join(","),
                });
            }

            return {
                PageSize: parseInt(this.perPage),
                CurrentPageNumber: parseInt(this.currentPage),
                FilterValue: this.filter,
                SortBy: this.sortBy,
                SortDesc: this.sortDesc,
                Filters: extrafilters,
                Status: parseInt(this.InsideIndex),
                IsDraftType: this.OutsideIndex == 1
            };
        },
        buildColumns() {
            let columns = [];

            columns.push({
                key: "Actions",
                label: "Actions",
            })
            columns.push({
                key: "AgencyName",
                label: "Agency",
                sortable: false,
            })
            columns.push({
                key: "RequestType",
                label: "Request Type",
                sortable: false,
            })
            columns.push({
                key: "ClientCode",
                label: "Client Name & Code",
                sortable: true,
                class: "NameHoverclass",
            })
            columns.push({
                key: "UnlockMediaType",
                label: "UnlockMediaType",
                visible: false,
                thClass: "d-none",
                tdClass: "d-none",
            })
            columns.push({
                key: "Requestor",
                label: "Requestor",
                sortable: true,
                class: "NameHoverclass",
            })
            columns.push({
                key: "Toolkit",
                label: "Toolkit",
                sortable: false,
            })
            columns.push({
                key: "Status",
                label: "Status",
                sortable: true,
            })
            columns.push({
                key: "CreatedDate",
                label: "Requested Date",
                sortable: true,
            })
            columns.push({
                key: "CompletiondDate",
                label: "Completed Date",
                sortable: true,
            });

            if (this.allRequest && this.InsideIndex != 3) {
                columns.push({
                    key: "CompletionDate",
                    label: "Date Completed",
                    sortable: true,
                    align: 'center'
                });
            }
            return columns;
        },
        DownloadList() {
            this.$store
                .dispatch("profileUnlock/DownloadList", this.GetGridOptions())
                .then((response) => {
                    const type = response.headers["content-type"];
                    const blob = new Blob([response.data], {
                        type: type,
                        encoding: "UTF-8",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Requests_${moment().format("MMDDYYYYmmhhss")}.xlsx`;
                    link.click();
                    link.remove();
                })
                .catch(() => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error downloading requests.`
                    );
                });
        },
        closeAudit() {
            this.closeAuditModal = true;
        },
        showAuditTrail(id, requestType) {
            this.requestId = id;
            this.requestType = requestType;
            this.closeAuditModal = false;
        },
        workflowAccessIssueAlert() {
            this.$store.dispatch(
                "progress/setErrorMessage",
                `Don't have permission to approve the request`
            );
        },
        // redirectToReview(id) {
        //     this.$router.push({
        //         name: `WireTransferReview`,
        //         params: {
        //             id: parseInt(id),
        //         },
        //     });
        // },
        redirectToProductView(id) {
            this.$router.push({
                name: `Product`,
                params: {
                    id: parseInt(id),
                },
            });
        },
        redirectToICView(id, isDraft) {
            this.$router.push({
                name: `InterCompany Agency`,
                params: {
                    requestid: parseInt(id),
                    isDraft: isDraft != undefined ? isDraft : false
                },
            });
        },
        navigateToEdit(id, requestType, status, email) {
            if (status == ProfileUnlockStates.ApproveLevel1) {
                if (this.canApproveProfileRequestLevel1) {
                    this.$router.push({
                        path: `Profile/ProfileApproveLevel1/${id}`
                    });
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to approve the request`
                    );
                }
            }  else if (status == ProfileUnlockStates.ApproveLevel2) {
                if (this.canApproveProfileRequestLevel2) {
                    this.$router.push({
                        path: `Profile/ProfileApproveLevel1/${id}`
                    });
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to approve the request`
                    );
                }
            } else if (status == ProfileUnlockStates.ApproveLevel3) {
                if (this.canApproveProfileRequestLevel3) {
                    this.$router.push({
                        path: `Profile/ProfileApproveLevel2/${id}`
                    });
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to approve the request`
                    );
                }
            }
            else if (status == ProfileUnlockStates.ApproveLevel4) {
                if (this.canApproveProfileRequestLevel4) {
                    this.$router.push({
                        path: `Profile/Complete/${id}`
                    });
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to approve the request`
                    );
                }
            }
            else if (status == ProfileUnlockStates.Rejected) {
                if (this.isRequestorbyEmail(email)) {
                    this.$router.push({
                        name: `Profile Unlock`,
                        params: {
                            requestid: parseInt(id)
                        },
                    })
                } else {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Don't have permission to edit the request`
                    );
                }
            } else if (status == ProfileUnlockStates.Finished) {
                this.$store.dispatch(
                    "progress/setErrorMessage",
                    `This Request is already completed nor started.`
                );
            }
        },

        GetRequests() {
            this.TotalRows = 0;
            this.data.rows = [];
            this.$store
                .dispatch("client/GetAllProfileUnlockRequests", this.GetGridOptions())
                .then((res) => {
                    if (res.data) {
                        this.TotalRows = res.data.Total;
                        this.data.rows = res.data.Result;
                        this.RecordsPerPageOption = UpdateRecordsPerPageOption(this.TotalRows);
                    }
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `${ex.response.data}`
                        );
                    } else {
                        this.$store.dispatch(
                            "progress/setErrorMessage",
                            `Error fetching requests.${ex}`
                        );
                    }
                });
        },
        FilterQuery: function () {
            this.currentPage = 1;
            this.GetRequests();
        },

        OnChange: function (val) {
            window.console.log(val);
        },

        select: function (val) {
            window.console.log(val);
        },
        ResetValues: function () {
            this.FilterList.Status = [];
            this.FilterList.Agency = [];
            this.FilterList.Toolkit = [];
            this.currentPage = 1;
            this.GetRequests();
        },
        closefilter() {
            this.theme = "row";
            this.ShowFilter = false;
        },
        openfilter() {
            (this.theme = "col-md-9"), (this.ShowFilter = true);
        },
        createNew(name) {
            this.$router.push(name);
        },
        getWindowWidth: function () {
            this.getFixed = document.documentElement.clientWidth > 992 ? true : null;
        },
        getBackgroundColor: function (status) {
            let color = "#8c8c8c";
            let emptyStatus = '';
            let emptyStatusNull = null;
            switch (status) {
                case ProfileUnlockStates.ApproveLevel1:
                    color = "#efa56a";
                    break;

                case ProfileUnlockStates.ApproveLevel2:
                    color = "#efa56a";
                    break;

                case ProfileUnlockStates.ApproveLevel3:
                    color = "#efa56a";
                    break;
                case ProfileUnlockStates.ApproveLevel4:
                    color = "#efa56a";
                    break;
                case ProfileUnlockStates.Finished:
                    color = "#2cc4ad";
                    break;
                case ProfileUnlockStates.Rejected:
                    color = "#df5678";
                    break;
                case ProfileUnlockStates.Canceled:
                    color = "#df5678";
                    break;
                case emptyStatus:
                    color = "";
                    break;
                case emptyStatusNull:
                    color = "";
                    break;
                default:
                    color = "#ccc";
                    break;
            }

            return {
                color: color,
                "font-weight": 600
            };
        },
        async LoadAgency() {
            service
                .getAgencyDropdownValues("")
                .then((res) => {
                    this.AgencyList = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },
    },
};
</script>
