export function formatRequestor(email) {
    let fullName = "";
    if (email) {
        var emailIndex = email.indexOf("@");
        var dotIndex = email.indexOf(".");
        if (emailIndex > -1 && dotIndex > -1) {
            var emailParts = email.slice(0, emailIndex).split(".");

            if (emailParts.length > 1) {
                var emailFirstName = emailParts[0];
                var emailLastName = emailParts[emailParts.length - 1];

                emailFirstName = emailFirstName[0].toUpperCase() + emailFirstName.substring(1);
                emailLastName = emailLastName[0].toUpperCase() + emailLastName.substring(1);

                fullName = `${emailFirstName} ${emailLastName}`;
            } else if (emailParts.length === 1) {
                fullName = emailParts[0][0].toUpperCase() + emailParts[0].substring(1);
            }
        }
    }
    return fullName;
}