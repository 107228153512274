<template>
    <div class="grid-container">
        <div class="row py-5 justify-content-center">
            <div class="col-3" v-if="IsOop">
                <nav-card class="d-block w-100" title="OOP" description="OOP Power App" :img-src="IconOOp" :smallRow="false" to="https://finsysreq-stage.oneomg.com" />
            </div>
            <div class="col-3" v-if="IsHeld">
                <nav-card class="d-block w-100" title="HELD" description="HELD Power App" :img-src="IconHeld" :smallRow="false" to="https://finsysreq-stage.oneomg.com" />
            </div>
            <div class="col-3" v-if="IsRno">
                <nav-card class="d-block w-100" title="RNO" description="RNO Power App" :img-src="IconRno" :smallRow="false" to="https://finsysreq-stage.oneomg.com" />
            </div>
        </div>
    </div>
    </template>

    <script>
    export default {
        name: "OtherForms",
        data() {
            return {
                IconOOp: require("@/assets/Icons/IconOOP.svg"),
                IconHeld: require("@/assets/Icons/IconHeld.svg"),
                IconRno: require("@/assets/Icons/IconRno.svg"),
                IsOop:true,
                IsHeld:true,
                IsRno:true,
            };
        },
        created() {
        },
        props: {
            allRequest: {
                type: Boolean,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
            requestid: {
                type: Number,
                required: false,
            },
        },
        mounted() {
            if(!this.IsOop && !this.IsHeld && !this.IsRno) {
                this.$router.push({
                    name: "unauthorized",
                    params: {
                        message: "UnAuthorized"
                    },
                });
            }
            this.$nextTick(function () {
                window.addEventListener("resize", this.getWindowWidth);
                window.console.log(this.$refs.picker);
            });
        },
        watch: {
        },
        computed: {
        },
    };
    </script>
<style>
.grid-container .card-img{
    width: 90px !important;
    max-width: 100% !important;
}
</style>