<template>
    <div style="padding-top: 35px">
        <div class="d-block row">
            <div class="col-lg-12">
                <div class="row" style="margin-top: -48px !important; margin-bottom: 10px !important">
                     <b-tabs :class="{ 'offset-lg-3': ShowFilter }" class="inside-tab" v-model="OutsideIndex" @input="tabChange()">
                      <b-tab title="Requests"> </b-tab>
                    </b-tabs>
                </div>
                <div class="row">
                    <div class="col-md-3" v-if="ShowFilter">
                        <div>
                            <div class="form-group filterSection col-md-12">
                                <label class="basic-class">Report Run as of Date</label>
                                <b-form-datepicker inputType="date" id="calendar-picker"
                                    :date-format-options="{ month: 'numeric', day: 'numeric' ,year: 'numeric'}"
                                    :max="new Date()" v-model="DateTime">
                                </b-form-datepicker>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="basic-class">Agency</label>
                                <OmniSelect v-model="AgencyIndex" :options="FilterTabOptions" name="" :showLabel="false" placeholder="Agency" :showSearch="false" />
                            </div>
                            <div class="form-group col-md-12">
                                <label class="basic-class">Security Role</label>
                                <OmniSelect v-model="RoleIndex" :options="FilterRoleOptions" name="" :showLabel="false" placeholder="Security Role" :showSearch="false" />
                            </div>
                            <div class="form-group col-md-12">
                                <label class="basic-class">User Name</label>
                                <input type="text" class="form-control" v-model="SearchData" placeholder="User Name" />
                            </div>
                            <div class="form-group col-md-12">
                                <label class="basic-class">Date Added/Date Modified</label>
                                <date-range-picker style="min-width: 98%" ref="picker" opens="right" :ranges="false" v-model="CompletionDate" placeholder="Date Added/Date Modified" :locale-data="locale">
                                    <template v-slot:input="picker">
                                        <span v-if="IsValidDate(picker)">
                                            {{ picker.startDate | formatDate }} -
                                            {{ picker.endDate | formatDate }}</span>
                                        <span v-else class="placeholder">
                                            Filter by Completion Date
                                        </span>
                                    </template>
                                </date-range-picker>
                            </div>
                            <div class="row text-right">
                                <div class="col-md-11 p-0">
                                    <button class="btn btn-primary text-uppercase" v-on:click="RestValues()">
                                        RESET
                                    </button>
                                    <button class="btn btn-primary text-uppercase" v-on:click="FilterQuery()">
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="[{ 'col-md-12 ': !ShowFilter, 'col-md-9': ShowFilter },]">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button class="text-left p-0 m-0 btn btn-link text-uppercase Open-Filter" @click="openfilter()" v-show="!ShowFilter">
                                            <b-img width="11" height="9" :src="openFilterIcon" /> Open Filter
                                        </button>
                                        <button v-show="ShowFilter" class="text-left p-0 m-0 btn btn-link text-uppercase Close-Filter" @click="closefilter()">
                                            <b-img width="11" height="9" :src="closeFilterIcon" /> Close Filter
                                        </button>
                                    </div>
                                    <div class="col-md-6 text-right">
                                        <b-button class="btn custom-button"  text="Download" variant="outline-primary" ref="popoverButton">
                                            Download
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                     <div class="row mb-2">
                        <b-popover placement="bottom" display="block" :target="$refs.popoverButton" :show.sync="show" variant="primary">
                            <div class="text-center  p-2">
                                <div>
                                    <p class="popoverhover" @click="DownloadList('Excel');show = !show">
                                        EXCEL
                                    </p>
                                </div>
                                <div>
                                    <p class="popoverhover m-0" @click="DownloadList('Pdf');show = !show">
                                        PDF
                                    </p>
                                </div>
                            </div>
                        </b-popover>

                     </div>
                        <b-table id="all-items" :per-page="perPage" :items="data.rows" :fields="fields" no-local-sorting=true @sort-changed="sortChange" show-empty striped hover responsive class="all-items">
                            <!-- <template v-slot:cell(CreatedDate)="data">
                                <div class="text-center">
                                    {{ data.value | formatDate }}
                                </div>
                            </template>
                               <template v-slot:cell(Name)="data">
                                <a class="edit-link">{{formatName(data.item)}}</a>
                                <div style="font-size:12px;">{{formatRequested(data.item)}}</div>
                            </template>
                             <template v-slot:table-colgroup="scope" v-if="allRequest">
                                <col v-for="field in scope.fields" :key="field.key" :style="{width: field.label==='Request'?'300px':field.key === 'audit' ? '50px':'auto',}" />
                            </template>
                            <template v-slot:cell(RequestType)="data">
                                <div class="text-center">
                                    {{ data.value  }}
                                </div>
                            </template>
                            <template v-slot:cell(CompletionDate)="data">
                                <div class="text-center">
                                    {{ data.value | formatDate }}
                                </div>
                            </template>
                            <template v-slot:cell(Requestor)="data">
                                <div>
                                    {{ formatRequestor(data.value) }}
                                </div>
                            </template>
                            <template v-slot:cell(Status)="data">
                                <div style="white-space: nowrap" class="text-left">
                                    <span :style="getBackgroundColor(data.value)">
                                        {{ formatStatus(data.value) }}
                                    </span>
                                </div>
                            </template> -->
                            <template slot="custom-foot" v-if="IsPaginationRequired">
                                <b-tr>
                                    <b-td class="custom-pagination" colspan="13">
                                        <b-form-select class="records-per-page" v-model="perPage" :options="RecordsPerPageOption"></b-form-select>
                                        <Pagination style="float: right" :per-page="perPage" aria-controls="open-items" v-model="currentPage" :total-rows="TotalRows" first-number last-number />
                                    </b-td>
                                </b-tr>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>

    <script>
    const moment = require("moment");
    import DateRangePicker from "vue2-daterange-picker";
    import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
    import XLSX from 'xlsx';
    export default {
        components: {
            DateRangePicker,
        },
        name: "UserRoles",
        data() {
            const jsonData = [
                {
                    "Form/Module": "Client",
                    "Agency": "Fan Connect",
                    "User": "Chandrasekar Natarajan",
                    "Role": "AR Approval",
                    "DateAdded": "03/09/2024",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Profile Unlock",
                    "Agency": "Doremus",
                    "User": "Ajay Singh",
                    "Role": "BPM",
                    "DateAdded": "03/10/2024",
                    "WhoAdded": "Ajay Singh",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Profile Unlock",
                    "Agency": "Doremus",
                    "User": "Ajay Singh",
                    "Role": "BPM",
                    "DateAdded": "03/10/2024",
                    "WhoAdded": "Ajay Singh",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Profile Unlock",
                    "Agency": "Doremus",
                    "User": "Ajay Singh",
                    "Role": "BPM",
                    "DateAdded": "03/10/2024",
                    "WhoAdded": "Ajay Singh",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Profile Unlock",
                    "Agency": "Doremus",
                    "User": "Ajay Singh",
                    "Role": "BPM",
                    "DateAdded": "03/10/2024",
                    "WhoAdded": "Ajay Singh",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Profile Unlock",
                    "Agency": "Doremus",
                    "User": "Ajay Singh",
                    "Role": "BPM",
                    "DateAdded": "03/10/2024",
                    "WhoAdded": "Ajay Singh",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Profile Unlock",
                    "Agency": "Doremus",
                    "User": "Ajay Singh",
                    "Role": "BPM",
                    "DateAdded": "03/10/2024",
                    "WhoAdded": "Ajay Singh",
                    "DateModified": "03/11/2024",
                    "WhoModified": "Chandrasekar Natarajan"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Doremus",
                    "User": "Shafiul Azam",
                    "Role": "AR Approval",
                    "DateAdded": "01/11/2024",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "01/22/2024",
                    "WhoModified": "Shafiul Azam"
                },
                {
                    "Form/Module": "Client",
                    "Agency": "Content Collective",
                    "User": "Shafiul Azam",
                    "Role": "User",
                    "DateAdded": "10/03/2021",
                    "WhoAdded": "Ranjita Khamari",
                    "DateModified": "12/05/2023",
                    "WhoModified": "Ranjita Khamari"
                }
            ];
            return {
                RecordsPerPageOption:[],
                DateTime: this.getYesterdayDate(),
                jsonData: jsonData,
                CancelNotes: null,
                ShowCancel: false,
                deleteIcon: require("@/assets/Icons/delete.svg"),
                collapseIcon: require("@/assets/Icons/arrow-down.svg"),
                expandIcon: require("@/assets/Icons/rightarrow.svg"),
                editIcon: require("@/assets/Icons/edit.svg"),
                FilterTabOptions: [{
                        label: 'Annalect',
                        value: "0"
                    },
                    {
                        label: 'Content Collective',
                        value: "1"
                    },
                    {
                        label: 'Doremus',
                        value: "2"
                    },
                    {
                        label: 'Fan Connect',
                        value: "3"
                    }
                ],
                FilterRoleOptions: [{
                        label: 'AR Approval',
                        value: "0"
                    },
                    {
                        label: 'BPM',
                        value: "1"
                    },
                    {
                        label: 'View Only',
                        value: "2"
                    },
                    {
                        label: 'User',
                        value: "3"
                    }
                ],
                locale: {
                    applyLabel: "Set",
                    cancelLabel: "Cancel",
                },
                CompletionDate: {
                    startDate: null,
                    endDate: null
                },
                button: "button title",
                AgencyIndex:null,
                RoleIndex: null,
                OutsideIndex: 0,
                TotalRows: 0,
                requestType: null,
                requestId: 0,
                closeAuditModal: true,
                addClient: require("@/assets/Icons/add-client.svg"),
                addProduct: require("@/assets/Icons/add-product.svg"),
                addIntercompany: require("@/assets/Icons/add-intercompany.svg"),
                addVendor: require("@/assets/Icons/add-vendor.svg"),
                closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
                openFilterIcon: require("@/assets/Icons/open-filter-icon.svg"),
                auditIcon: require("@/assets/Icons/auditTrail_icon.svg"),
                SearchData: null,
                sortBy: "CreatedDate",
                sortDesc: true,
                theme: "row",
                ShowFilter: false,
                OmniflowStatus: {
                    ICApproveLevel1: "Intercompany Approval",
                    ICApproveLevel2: "Finance Approval",
                    ICApproveLevel3: "D365 Approval",
                    Rejected: "Rejected",
                    Completed: "Completed",
                },
                RequestTypes: [{
                        value: "1",
                        label: "Client"
                    },
                    {
                        value: "2",
                        label: "Product"
                    },
                    {
                        value: "3",
                        label: "Inter Company"
                    },
                    {
                        value: "4",
                        label: "Vendor"
                    },
                ],
                RequestTypeFields: {
                    text: "RequestType",
                    value: "Id"
                },

                Status: [],
                RequestStatusFields: {
                    text: "RequestSts",
                    value: "Id"
                },
                MediaTypeField: {
                    text: "MediaTypeVal",
                    value: "Id"
                },
                showSelectAll: true,
                getFixed: true,
                dismissSecs: 5,
                dismissCountDown: 0,
                showDismissibleAlert: false,
                showfilter: false,
                showfilterOpenItems: false,
                perPage: 10,
                currentPage: 1,
                DateFormat: "MM/DD/YYYY",
                elegant: "black",
                fields: this.buildColumns(),
                data: {
                    config: {
                        card_mode: false,
                        show_refresh_button: false,
                        show_reset_button: false,
                        pagination_info: true,
                        global_search: {
                            visibility: false,
                        },
                    },
                    loading: false,
                    classes: {
                        tableWrapper: "outer-table-div-class wrapper-class-two grid-table",
                        table: {
                            "table-sm": true,
                        },
                    },
                    rows: [],
                },
            };
        },
        created() {
            this.$store.commit("client/reset");
        },
        props: {
            allRequest: {
                type: Boolean,
                required: true,
            },
            index: {
                type: Number,
                required: true,
            },
            requestid: {
                type: Number,
                required: false,
            },
        },
        mounted() {
            if (this.CanAccessList) {
                this.GetRequests();
            } else {
                this.$router.push({
                    name: "unauthorized",
                    params: {
                        message: "UnAuthorized"
                    },
                });
            }
            this.$nextTick(function () {
                window.addEventListener("resize", this.getWindowWidth);
                window.console.log(this.$refs.picker);
            });
        },
        watch: {
            AgencyIndex: function () {
                this.fields = this.buildColumns();
                this.GetRequests();
            },
            RoleIndex: function () {
                this.fields = this.buildColumns();
                this.GetRequests();
            },
            currentPage: function (value) {
                if (value != null && parseInt(value) > 0) {
                    this.GetRequests();
                }
            },
            perPage: function () {
                this.currentPage = 1;
                this.GetRequests();
            }
        },
        computed: {
            IsPaginationRequired() {
                return this.TotalRows != null && parseInt(this.TotalRows) > parseInt(this.perPage);
            },

            HasAccessToAnyRequests() {
                return  this.CanCreateICRequest;
            },

            CanAccessList() {
                return this.$store.getters.canAccessList;
            },
        },
        filters: {
            formatDate(date) {
                return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
            },
            formatDateAndTime(date) {
                return moment(date).isValid() ? moment(date).format("MM/DD/YYYY h:mma") : "-";
            },
        },
        methods: {
            UpdateRecordsPerPageOption() {
                const ratio5 = this.TotalRows / 5;
                const ratio10 = this.TotalRows / 10;
                const ratio15 = this.TotalRows / 15;
                const ratio20 = this.TotalRows / 20;
                const ratio25 = this.TotalRows / 25;
                this.RecordsPerPageOption = [];
                if(ratio5 > 2) this.RecordsPerPageOption.push(5);
                if(ratio10 > 2) this.RecordsPerPageOption.push(10);
                if(ratio15 > 2) this.RecordsPerPageOption.push(15);
                if(ratio20 > 2) this.RecordsPerPageOption.push(20);
                if(ratio25 > 2) this.RecordsPerPageOption.push(25);
            },
            getYesterdayDate() {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return yesterday.toISOString().split('T')[0];
            },
            tabChange() {
                this.fields = this.buildColumns();
            },
            IsValidDate(completionDate) {
                return (
                    completionDate != null &&
                    moment(completionDate.startDate).isValid() &&
                    moment(completionDate.endDate).isValid()
                );
            },
            onFiltered(e) {
                if ((e != null && e.length > 2) || e == null || e.length == 0) {
                    this.currentPage = 1;
                    this.GetRequests();
                }
            },
            sortChange(e) {
                this.sortBy = e.sortBy;
                this.sortDesc = e.sortDesc;
                this.GetRequests();
            },
            GetGridOptions() {
                let extrafilters = [];
                if (
                    this.FilterList.CompletionDate != null &&
                    this.FilterList.CompletionDate.hasOwnProperty("startDate") &&
                    this.FilterList.CompletionDate.startDate != null
                ) {
                    extrafilters.push({
                        Field: "CompletionDate",
                        Operator: "between",
                        Value: JSON.stringify(this.FilterList.CompletionDate),
                    });
                }

                if (
                    this.FilterList.Status != null && this.FilterList.Status != ""
                ) {
                    extrafilters.push({
                        Field: "Status",
                        Operator: "=",
                        Value: this.FilterList.Status.join(","),
                    });
                }
                return {
                    PageSize: parseInt(this.perPage),
                    CurrentPageNumber: parseInt(this.currentPage),
                    FilterValue: this.SearchData,
                    SortBy: this.sortBy,
                    SortDesc: this.sortDesc,
                    Filters: extrafilters,
                    IsDraftType: this.OutsideIndex==1
                };
            },
            buildColumns() {
                    let columns = [];

                    columns.push({
                        key: "Form/Module",
                        label: "Form/Module",
                    })
                    columns.push({
                        key: "Agency",
                        label: "Agency",
                    })
                    columns.push({
                        key: "User",
                        label: "User",
                    })
                    columns.push({
                        key: "Role",
                        label: "Role",
                    })
                    columns.push({
                        key: "DateAdded",
                        label: "Date Added",
                        // sortable: true,
                    })
                    columns.push({
                        key: "WhoAdded",
                        label: "Who Added",
                    })
                    columns.push({
                        key: "DateModified",
                        label: "Date Modified",
                        // sortable: true,
                    })
                    columns.push({
                        key: "WhoModified",
                        label: "Who Modified",
                    })
                    return columns;
                },
            DownloadList(fileType) {
                let fileExtension = fileType === "Excel"? "xlsx" : "pdf"
                const worksheet = XLSX.utils.json_to_sheet(this.jsonData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
                const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Requests_${moment().format('MMDDYYYYmmhhss')}.` + fileExtension;
                link.click();
                link.remove();
            },
            GetRequests() {
                this.data.rows = this.jsonData;
                this.TotalRows = this.jsonData.length;
                console.log(this.TotalRows,"this.TotalRows")
                this.UpdateRecordsPerPageOption();
            },
            FilterQuery: function () {
                const filteredData = this.jsonData.filter(item => {
                    let agencyFilterPassed = true;
                    let roleFilterPassed = true;
                    let dateFilterPassed = true;
                    let userNameFilterPassed = true;
                    let dateRangeFilterPassed = true;
                    if (this.DateTime && item.DateAdded !== null) {
                        dateFilterPassed = item.DateAdded === moment(this.DateTime).format("MM/DD/YYYY");
                    }
                    if (this.AgencyIndex !== null && item.Agency !== null) {
                        agencyFilterPassed = item.Agency === this.FilterTabOptions[this.AgencyIndex].label;
                    }
                    if (this.RoleIndex !== null && item.Role !== null) {
                        roleFilterPassed = item.Role === this.FilterRoleOptions[this.RoleIndex].label;
                    }
                    if (this.SearchData !== null && item.User !== null && item.User.length > 2) {
                        userNameFilterPassed = item.User.toLowerCase() === this.SearchData.toLowerCase();
                    }
                    if (this.CompletionDate.startDate && this.CompletionDate.endDate) {
                        const startDate = moment(this.CompletionDate.startDate).format("MM/DD/YYYY");
                        const endDate = moment(this.CompletionDate.endDate).format("MM/DD/YYYY");
                        const itemDateAdded = moment(item.DateAdded, "MM/DD/YYYY");
                        const itemDateModified = moment(item.DateModified, "MM/DD/YYYY");
                        dateRangeFilterPassed = itemDateAdded.isSameOrAfter(startDate) && itemDateModified.isSameOrBefore(endDate);
                    }
                    return agencyFilterPassed && roleFilterPassed && dateFilterPassed && dateRangeFilterPassed && userNameFilterPassed;
                });
                this.data.rows = filteredData;
            },

            OnChange: function (val) {
                alert(val);
            },

            select: function (val) {
                alert(val);
            },
            RestValues: function () {
                this.$set(this.CompletionDate, "startDate", null);
                this.$set(this.CompletionDate, "endDate", null);
                this.Agency = [];
                this.SecurityRole = [];
                this.AgencyIndex = null;
                this.RoleIndex = null;
                this.DateTime = this.getYesterdayDate();
                this.SearchData = null;
                this.GetRequests();
            },
            closefilter() {
                this.theme = "row";
                this.ShowFilter = false;
            },
            openfilter() {
                (this.theme = "col-md-9"), (this.ShowFilter = true);
            },
            getWindowWidth: function () {
                this.getFixed = document.documentElement.clientWidth > 992 ? true : null;
            },
        },
    };
    </script>
<style>
.filterSection .b-calendar .b-calendar-inner {
    min-width: 250px;
    width: 100% !important;
}
.filterSection .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    max-width: 35px !important;
    min-width: 35px !important;
    max-height: 35px !important;
    min-height: 35px !important;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
</style>