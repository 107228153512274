import { render, staticRenderFns } from "./MediaWireTransferRequestTable.vue?vue&type=template&id=12cd8502&scoped=true"
import script from "./MediaWireTransferRequestTable.vue?vue&type=script&lang=js"
export * from "./MediaWireTransferRequestTable.vue?vue&type=script&lang=js"
import style0 from "./MediaWireTransferRequestTable.vue?vue&type=style&index=0&id=12cd8502&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12cd8502",
  null
  
)

export default component.exports