const  

ApproveLevel1 = "BPM Approval", 
ApproveLevel2 = "MR Approval",
ApproveLevel3 = "Unlock Request",
ApproveLevel4 = "Relock Request",  
Final="Final task", 
Canceled = "Cancel",
Finished="Complete",
Rejected="Reject";


export {ApproveLevel1,ApproveLevel2,ApproveLevel3,ApproveLevel4,Final,Finished,Canceled,Rejected};