const  ApproveLevel1 = "Intercompany Approval", ApproveLevel2 = "Finance Approval", OpenCodeInAxandDDs = "Open codes in AX and DDS", AxUpdateTheRequestWithSystemCode = "D365 Approval",Final = "Completed", Canceled = "Rejected";

const AllStates=[
    ApproveLevel1,
    ApproveLevel2,
    AxUpdateTheRequestWithSystemCode,
    Final,
    Canceled,
]

const PendingRequestStates=[
    ApproveLevel1,
]
const PendingApprovalStates=[
    ApproveLevel2, 
    OpenCodeInAxandDDs
]

const PendingCompletionStates=[
    AxUpdateTheRequestWithSystemCode,
]
export { Final,Canceled,ApproveLevel1,ApproveLevel2,OpenCodeInAxandDDs,AxUpdateTheRequestWithSystemCode, AllStates,PendingApprovalStates,PendingCompletionStates,PendingRequestStates };